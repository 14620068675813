<template>
    <div>
        <div class="section-box">
            <div v-if="categoryList.length > 0">
                <swiper class="category-swiper" :freeMode="true" :slidesPerView="'auto'">
                    <swiper-slide class="category-item" v-for="(category, key) in categoryList" :key="key"
                        :class="{ active: selectedCategoryId && selectedCategoryId == category.id }">
                        <div @click="selectCategory(category)">
                            {{ category.title }}
                        </div>
                    </swiper-slide>
                </swiper>
            </div>

            <div v-if="selectedCategoryId">
                <div class="menu-list">
                    <draggable v-model="getSelectedCategory().childs" handle=".handle"
                        @change="reorderItem(getSelectedCategory().childs)">
                        <div class="item" v-for="(item, key) in getSelectedCategory().childs" :key="key">
                            <v-layout align-center>
                                <v-icon class="handle">mdi-menu</v-icon>
                                <v-flex>
                                    {{ item.title }}
                                </v-flex>
                                <!-- <hh-input v-model="item.title"></hh-input> -->
                                <v-btn icon color="primary" @click="openItemDialog(getSelectedCategory(), item)">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>

                            </v-layout>
                        </div>
                    </draggable>
                    <div class="text-center">
                        <v-btn text color="primary" @click="openItemDialog(getSelectedCategory())">메뉴추가</v-btn>
                    </div>
                </div>
            </div>
        </div>



        <v-dialog v-model="itemDialog.visible" :transition="false" width="500px" content-class="item-dialog">
            <!-- <div class="dialog-header" v-if="itemDialog.item">
                <v-layout>
                    <v-flex>
                        <div class="title">
                            {{ itemDialog.item.id ? "메뉴 수정" : "메뉴 추가" }}
                        </div>
                    </v-flex>
                    <div>
                        <v-btn icon @click="itemDialog.visible = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>
                </v-layout>
            </div> -->
            <v-card class="contents" v-if="itemDialog.item">

                <div class="text-center">
                    <div class="image-box-container">

                        <image-box class="image-container" @change="changeItemImage"
                            :prefill="getPrefill(itemDialog.item)"></image-box>
                    </div>

                </div>
                <div class="input-header">제목</div>
                <hh-input class="input" v-model="itemDialog.item.title" placeholder="제목"></hh-input>
                <div class="input-header">가격</div>
                <hh-money class="input" v-model="itemDialog.item.price" suffix="원"></hh-money>
                <v-checkbox hide-details v-model="itemDialog.item.soldout" label="품절"></v-checkbox>
                <v-checkbox hide-details v-model="itemDialog.item.recommended" label="추천"></v-checkbox>
                <v-checkbox hide-details v-model="itemDialog.item.isVisibleStore" label="매장주문가능"></v-checkbox>
                <v-checkbox hide-details v-model="itemDialog.item.isVisibleTakeout" label="포장주문가능"></v-checkbox>
                <v-checkbox hide-details v-model="itemDialog.item.isCheckID" label="신분증 확인메뉴"></v-checkbox>

                <div class="input-header">설명</div>

                <v-textarea class="input" solo flat hide-details v-model="itemDialog.item.description"
                    placeholder="설명"></v-textarea>

                <div class="input-header">옵션</div>
                <v-layout align-center class="input option-list">
                    <v-select class="option-item" v-model="itemDialog.selectedOption" :items="optionList"
                        item-text="name" item-value="id" solo flat hide-details return-object></v-select>
                    <v-btn color="primary" text @click="itemAddOption(itemDialog.selectedOption)">추가</v-btn>
                </v-layout>

                <div class="option-list">
                    <div class="option-item" v-for="(option, key) in itemDialog.item.options"
                        :class="{ removed: option.removed }">
                        <v-layout align-center>
                            <div>
                                {{ option.name }}
                            </div>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="itemRemoveOption(option)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-layout>

                    </div>
                </div>

                <div class="text-center mt-2">
                    <v-btn class="mr-4" v-if="itemDialog.item.id" color="error" depressed @click="removeItem">삭제</v-btn>
                    <v-btn v-if="itemDialog.item.id" color="primary" depressed @click="itemModify">수정</v-btn>

                    <v-btn v-else color="primary" depressed @click="itemSubmit">추가</v-btn>

                </div>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
    import _ from "lodash"
    import draggable from "vuedraggable";
    export default {
        components: {
            draggable
        },
        data() {
            return {
                categoryList: [],
                selectedCategoryId: null,
                file: null,
                itemDialog: {
                    visible: false,
                    item: null,
                    selectedOption: null
                },
            }
        },
        mounted() {
            this.getCategoryList()
            this.getOptionList()
        },
        methods: {
            itemRemoveOption(option) {
                option.removed = true

            },
            getSelectedCategory() {
                if (this.selectedCategoryId) {
                    return _.find(this.categoryList, { id: this.selectedCategoryId })
                }
                else {
                    return null
                }

            },
            itemAddOption(option) {
                if (!option) {
                    return;
                }
                if (!this.itemDialog.item.options) {
                    this.itemDialog.item.options = [];
                }
                this.itemDialog.item.options.push(_.clone(option))
            },
            getOptionList() {
                this.$axios.post("/option/list")
                    .then(result => {
                        if (result.data.res) {
                            this.optionList = result.data.list;
                        }
                    })
            },
            changeItemImage(file) {
                this.itemDialog.item.file = file;
            },
            changeImage(image) {
                this.file = image;
            },
            getPrefill(item) {

                if (item && item.image && item.image.imageUrl) {
                    return item.image.imageUrl;
                }
            },
            openItemDialog(category, item) {
                this.itemDialog.item = item || {
                    file: null,
                    MenuCategoryId: category.id,
                    title: "",
                    description: "",
                    price: null,
                    isVisibleStore: true,
                    isVisibleTakeout: true,
                    options: []
                };
                this.itemDialog.visible = true;
            },
            selectCategory(category) {
                if (category) {
                    this.selectedCategoryId = category.id
                }

            },
            getCategoryList() {
                this.$axios.post("/category/list")
                    .then(result => {
                        if (result.data.res) {
                            this.categoryList = result.data.list;
                            if (this.categoryList.length > 0 && !this.selectedCategoryId) {
                                this.selectCategory(_.first(this.categoryList))
                            }
                        }
                    })
            },
            reorderItem(list) {
                console.log(list);
                _.each(list, (item, key) => {
                    item.order = key;
                });
                this.$axios.post("/category/item/reorder", {
                    list: list,
                });
            },
            async itemModify() {
                if (this.itemDialog.item.file) {
                    var formData = new FormData();
                    formData.append("file", this.itemDialog.item.file);
                    var { data } = await this.$axios.post("/file/upload", formData);
                    this.itemDialog.item.imageId = data.file.id;
                }
                this.$axios
                    .post("/category/item/modify", this.itemDialog.item)
                    .then((result) => {
                        this.itemDialog.visible = false;
                        this.itemDialog.item = null;
                        this.getCategoryList();
                    });
            },
            async itemSubmit() {
                if (this.itemDialog.item.file) {
                    var formData = new FormData();
                    formData.append("file", this.itemDialog.item.file);
                    var { data } = await this.$axios.post("/file/upload", formData);
                    this.itemDialog.item.imageId = data.file.id;
                }
                this.$axios
                    .post("/category/item/add", this.itemDialog.item)
                    .then((result) => {
                        this.itemDialog.visible = false;
                        this.itemDialog.item = null;
                        this.getCategoryList();
                    });
            },
            removeItem() {
                if (!window.confirm(`${this.itemDialog.item.title} 메뉴를 삭제하시겠습니까?`)) {
                    return
                }
                this.$axios.post("/category/item/remove", this.itemDialog.item)
                    .then(result => {
                        if (result.data.res) {
                            this.itemDialog.visible = false;
                            this.itemDialog.item = null;
                            this.getCategoryList();
                        }
                    })
            },
        }
    }
</script>

<style scoped lang="less">
    .section-box {
        width: 800px;
    }

    .category-swiper {
        .category-item {
            width: initial;
            padding: 5px 20px;
            font-size: 18px;
            font-weight: 700;

            cursor: pointer;

            &.active {
                background: @primary-color;
                border-radius: 10px;
                color: white;
            }
        }
    }

    .image-box-container {
        margin: 0 auto;
        width: 200px;
        height: 200px;
    }

    .option-item {
        &.removed {
            text-decoration: line-through;
        }
    }

    .item-dialog {
        overflow-x: hidden;

        .dialog-header {
            // background: @primary-color;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
            background: white;
            padding: 5px;

            .title {
                font-size: 20px;
                font-weight: 700;
            }
        }

        .contents {
            background: white;
            padding: 20px;
            overflow-x: hidden;

            .option-list {
                .option-item {
                    padding: 5px 10px;
                    border-bottom: 1px solid #eee;
                }
            }
        }
    }
</style>