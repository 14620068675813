import { render, staticRenderFns } from "./_tableId.vue?vue&type=template&id=574bc82c&scoped=true"
import script from "./_tableId.vue?vue&type=script&lang=js"
export * from "./_tableId.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "574bc82c",
  null
  
)

export default component.exports