<template>
  <div class="root">
    <div class="form" v-if="item">
      <div class="section-box">
        <div class="text-right">
          <v-btn color="primary" v-if="$route.params.id == 'create'" @click="submit" outlined>매장 추가</v-btn>
          <v-btn color="primary" v-else @click="submit" outlined>매장 수정</v-btn>
        </div>
        <table class="table mt-2">
          <tr>
            <th width="140px">매장ID</th>
            <td>
              {{ item.id }}
            </td>
          </tr>
          <tr>
            <th width="140px">키오스크 이미지</th>
            <td>
              <div class="imagebox-container">
                <image-box :prefill="getPrefill(item)" @change="changeImage"></image-box>
              </div>
            </td>
          </tr>
          <tr>
            <th width="140px">키오스크 영상</th>
            <td>
              <div class="imagebox-container">
                <input-file @change="changeKioskCoverVideo" accept="video/*">
                  <v-btn color="primary" outlined>영상선택</v-btn>
                </input-file>
                <div v-if="kioskCoverVideo">
                  {{ kioskCoverVideo.name }}
                  <v-btn color="red">삭제</v-btn>
                </div>
                <div v-if="item.kioskCoverVideo">
                  {{ item.kioskCoverVideo.originalname }}
                  <v-btn color="error" outlined
                    @click="item.kioskCoverVideo = null; item.kioskCoverVideoId = null">삭제</v-btn>
                </div>

              </div>
            </td>
          </tr>
          <tr>
            <th>
              <div>키오스크 멀티이미지</div>
              <input-file @change="changeCovers" accept="image/*">
                <v-btn color="primary" outlined>사진선택</v-btn>
              </input-file>
            </th>
            <td>

              <div class="image-list">
                <div class="iamge-item" v-for="(img, key) in item.coverImages" v-if="!img.deleted">
                  <v-icon class="close-btn" @click="removeImage(item.coverImages, img)">mdi-close</v-icon>
                  <v-img class="image" :key="key" :src="img.imageMUrl || img.data"></v-img>
                </div>
              </div>

            </td>
          </tr>
          <tr>
            <th>
              테이블오더 <br>커버이미지
              <input-file @change="changeTableCover" accept="image/*">
                <v-btn color="primary" outlined>사진선택</v-btn>
              </input-file>
            </th>
            <td>
              <div class="cover-image" v-for="(coverImage, key) in item.images" :key="key" v-if="!coverImage.deleted">
                <v-layout>
                  <div>
                    <v-img class="image"
                      :src="(coverImage.image && coverImage.image.imageMUrl) || (coverImage.file && coverImage.file.image)"></v-img>
                  </div>
                  <v-flex>
                    <div class="header">노출시간</div>
                    <div>
                      <time-picker v-model="coverImage.visibleStartTime" label="노출시작시간"></time-picker> ~ <time-picker
                        v-model="coverImage.visibleEndTime" label="노출시작시간"></time-picker>
                    </div>
                    <div>
                      <v-layout>
                        <v-checkbox class="ma-0 pa-0" solo flat hide-details v-model="coverImage.visibleMon"
                          label="월"></v-checkbox>
                        <v-checkbox class="ma-0 pa-0" solo flat hide-details v-model="coverImage.visibleTue"
                          label="화"></v-checkbox>
                        <v-checkbox class="ma-0 pa-0" solo flat hide-details v-model="coverImage.visibleWed"
                          label="수"></v-checkbox>
                        <v-checkbox class="ma-0 pa-0" solo flat hide-details v-model="coverImage.visibleThu"
                          label="목"></v-checkbox>
                        <v-checkbox class="ma-0 pa-0" solo flat hide-details v-model="coverImage.visibleFri"
                          label="금"></v-checkbox>
                        <v-checkbox class="ma-0 pa-0" solo flat hide-details v-model="coverImage.visibleSat"
                          label="토"></v-checkbox>
                        <v-checkbox class="ma-0 pa-0" solo flat hide-details v-model="coverImage.visibleSun"
                          label="일"></v-checkbox>
                      </v-layout>

                    </div>

                    <div class="header">미노출시간</div>
                    <div>
                      <time-picker v-model="coverImage.hiddenStartTime" label="노출시작시간"></time-picker> ~ <time-picker
                        v-model="coverImage.hiddenEndTime" label="노출시작시간"></time-picker>
                    </div>
                    <div>
                      <v-layout>
                        <v-checkbox class="ma-0 pa-0" solo flat hide-details v-model="coverImage.hiddenMon"
                          label="월"></v-checkbox>
                        <v-checkbox class="ma-0 pa-0" solo flat hide-details v-model="coverImage.hiddenTue"
                          label="화"></v-checkbox>
                        <v-checkbox class="ma-0 pa-0" solo flat hide-details v-model="coverImage.hiddenWed"
                          label="수"></v-checkbox>
                        <v-checkbox class="ma-0 pa-0" solo flat hide-details v-model="coverImage.hiddenThu"
                          label="목"></v-checkbox>
                        <v-checkbox class="ma-0 pa-0" solo flat hide-details v-model="coverImage.hiddenFri"
                          label="금"></v-checkbox>
                        <v-checkbox class="ma-0 pa-0" solo flat hide-details v-model="coverImage.hiddenSat"
                          label="토"></v-checkbox>
                        <v-checkbox class="ma-0 pa-0" solo flat hide-details v-model="coverImage.hiddenSun"
                          label="일"></v-checkbox>
                      </v-layout>

                    </div>


                  </v-flex>
                  <div>
                    <v-btn @click="removeCoverImage(coverImage)" text color="red">삭제</v-btn>
                  </div>
                </v-layout>
              </div>
            </td>
          </tr>
          <tr>
            <th width="140px">매장이름</th>
            <td>
              <hh-input v-model="item.title"></hh-input>
            </td>
          </tr>
          <tr>
            <th width="140px">대표자이름</th>
            <td>
              <hh-input v-model="item.ownerName"></hh-input>
            </td>
          </tr>

          <tr>
            <th width="140px">사업자등록번호</th>
            <td>
              <v-text-field solo flat hide-details v-mask="'###-##-#####'" v-model="item.registNumber"></v-text-field>
            </td>
          </tr>
          <tr>
            <th>매장 주소</th>
            <td>
              <hh-input v-model="item.address"></hh-input>
            </td>
          </tr>


          <tr>
            <th>매장 아이디</th>
            <td>
              <hh-input v-model="item.deviceId"></hh-input>
            </td>
          </tr>
          <tr>
            <th>비밀번호</th>
            <td>
              <hh-input v-model="item.password"></hh-input>
            </td>
          </tr>
          <tr>
            <th>프로필 아이디</th>
            <td>
              <hh-input v-model="item.profileId"></hh-input>
            </td>
          </tr>
          <tr>
            <th>영수증메모</th>
            <td>
              <hh-input v-model="item.receptMemo"></hh-input>
            </td>
          </tr>
          <tr>
            <th>메인색</th>
            <td>
              <v-layout>
                <hh-input v-model="item.primaryColor"></hh-input>
                <div>
                  <v-text-field v-model="item.primaryColor" type="color" solo flat hide-details></v-text-field>
                </div>
              </v-layout>
            </td>
          </tr>
          <tr>
            <th>키오스크 한줄당 메뉴갯수</th>
            <td>
              <hh-money v-model="item.kioskGridColumn"></hh-money>
            </td>
          </tr>
          <tr>
            <th>KDS 사용여부</th>
            <td class="pa-2">
              <v-switch solo falt hide-details class="pa-0 ma-0" v-model="item.useKDS"></v-switch>
            </td>
          </tr>

          <tr>
            <th>POS 사용여부</th>
            <td class="pa-2">
              <v-switch solo falt hide-details class="pa-0 ma-0" v-model="item.usePOS"></v-switch>
            </td>
          </tr>

          <tr>
            <th>QR주문 사용여부</th>
            <td class="pa-2">
              <v-switch solo falt hide-details class="pa-0 ma-0" v-model="item.useMobileOrder"></v-switch>
            </td>
          </tr>

          <tr>
            <th>결제방법</th>
            <td class="pa-2">
              <v-switch solo falt hide-details class="pa-0 ma-0" label="카드결제" disabled
                v-model="item.usePyamentCard"></v-switch>
              <v-switch solo falt hide-details class="pa-0 ma-0" label="제로페이" v-model="item.usePaymentZeroPay"></v-switch>
              <v-switch solo falt hide-details class="pa-0 ma-0" label="식권대장" v-model="item.usePaymentSikdae"></v-switch>
            </td>
          </tr>
          <tr v-if="item.usePaymentSikdae">
            <th>식권대장 정보</th>
            <td class="pa-2">
              <div class="input-header">식권대장 클라이언트 아이디</div>
              <hh-input class="input" v-model="item.sikdaeClientId"></hh-input>
              <div class="input-header">식권대장 시크릿 키</div>
              <hh-input class="input" v-model="item.sikdaeClientSecret"></hh-input>
              <div class="input-header">식권대장 브랜드 코드</div>
              <hh-input class="input" v-model="item.sikdaeBrandCode"></hh-input>
              <div class="input-header">식권대장 점포 코드</div>
              <hh-input class="input" v-model="item.sikdaeShopCode"></hh-input>
            </td>
          </tr>

        </table>





      </div>
      <div class="section-box mt-2">
        <div class="section-header">포스설정</div>
        <table class="table">
          <tr>
            <th width="150px">
              <div>
                주방프린터
              </div>
              <v-btn text color="primary" @click="openOrderPrinterDialog">추가</v-btn>
            </th>
            <td>
              <div class="printer-list">
                <div class="printer-item" v-for="(orderPrinter, key) in item.orderPrinters" :key="key">
                  <!-- <div class="text-right">
                    <v-btn color="error" icon @click="removeOrderPrinter(orderPrinter)"><v-icon>mdi-close</v-icon></v-btn>
                  </div> -->
                  <div class="name">
                    이름 : {{ orderPrinter.name }}
                  </div>
                  <div class="port">
                    포트 : {{ orderPrinter.portName }}
                  </div>
                  <div class="type">
                    타입 : {{ orderPrinter.printerType | printerType }}
                  </div>
                  <div class="pos" v-if="orderPrinter.device">
                    POS : {{ orderPrinter.device.displayName }}
                  </div>
                  <div class="text-center">
                    <v-btn color="primary" text @click="openOrderPrinterDialog(orderPrinter)">추가</v-btn>
                    <v-btn color="error" text @click="removeOrderPrinter(orderPrinter)">삭제</v-btn>
                  </div>
                </div>
              </div>

            </td>
          </tr>
          <tr>
            <th width="150px">결제프린터</th>
            <td>
              <v-select v-model="item.posPaymentPrinterPort" :items="portNames" solo flat hide-details></v-select>
            </td>
          </tr>
        </table>
      </div>
      <div class="section-box mt-2">
        <div class="section-header">테이블 오더 설정
        </div>
        <table class="table">
          <tr>
            <th width="150px">상단 안내문구
              <v-layout>
                <v-spacer></v-spacer>
                <v-switch v-model="item.tableOrderNoticeMessageEnabled" solo hide-details
                  class="pa-0 ma-0 mt-2"></v-switch>
                <v-spacer></v-spacer>
              </v-layout>
            </th>
            <td>
              <v-textarea v-model="item.tableOrderNoticeMessage" solo flat hide-details></v-textarea>
            </td>
          </tr>
          <tr>
            <th>직원호출</th>
            <td>
              <v-switch v-model="item.tableOrderCallStaffEnabled" solo hide-details class="pa-0 ma-0 mt-2"></v-switch>
            </td>
          </tr>
          <tr v-if="item.tableOrderCallStaffEnabled">
            <th>직원 호출 프린터</th>
            <td>
              <v-select v-model="item.posStaffCallPrinterPort" :items="portNames" solo flat hide-details></v-select>
            </td>
          </tr>
          <tr>
            <th>주문프린터 글씨크기</th>
            <td>
              <v-select v-model="item.orderPrinterFontSize" :items="[11, 22, 33]" solo flat hide-details></v-select>
            </td>
          </tr>
          <tr>
            <th>주문프린터 메뉴명자르기</th>
            <td>
              <v-switch v-model="item.orderPrinterSubstring" solo hide-details class="pa-0 ma-0 mt-2"></v-switch>
            </td>
          </tr>
          <tr>
            <th>다국어사용
              <v-layout justify-center>
                <v-switch solo hide-details v-model="item.useMultiLanguage"></v-switch>
              </v-layout>
            </th>
            <td>
              <v-switch solo hide-details :disabled="!item.useMultiLanguage" v-model="item.useMultiLanguageEnglish"
                label="영어"></v-switch>
              <v-switch solo hide-details :disabled="!item.useMultiLanguage" v-model="item.useMultiLanguageJapanese"
                label="중국어"></v-switch>
              <v-switch solo hide-details :disabled="!item.useMultiLanguage" v-model="item.useMultiLanguageChinese"
                label="일본어"></v-switch>
            </td>
          </tr>
          <tr>
            <th>결제 CAT ID</th>
            <td>
              <hh-input v-model="item.paymentCatId"></hh-input>
            </td>
          </tr>
          <tr>
            <th>교육모드</th>
            <td>
              <v-switch v-model="item.isEducationMode" solo hide-details class="pa-0 ma-0 mt-2"></v-switch>
            </td>
          </tr>


        </table>
      </div>


      <div class="section-box mt-2">
        <div class="section-header">알림톡</div>
        <div class="tab-contents">
          <table class="table">
            <tr>
              <th>
                <div>
                  주문시 알림
                </div>
                <v-layout>
                  <v-spacer></v-spacer>
                  <v-switch v-model="item.useKakaoOrder" solo hide-details class="pa-0 ma-0 mt-2"></v-switch>
                  <v-spacer></v-spacer>
                </v-layout>





              </th>
              <td>
                <hh-input placeholder="템플릿 ID" class="input" :disabled="!item.useKakaoOrder"
                  v-model="item.kakaoOrderId"></hh-input>
                <v-textarea class="input mt-2" placeholder="템플릿" solo flat hide-details v-model="item.kakaoOrderTmpl"
                  :disabled="!item.useKakaoOrder"></v-textarea>
              </td>
            </tr>
            <tr>
              <th>
                <div>
                  호출시 알림
                </div>
                <v-layout>
                  <v-spacer></v-spacer>
                  <v-switch v-model="item.useKakaoComplete" solo hide-details class="pa-0 ma-0 mt-2"></v-switch>
                  <v-spacer></v-spacer>
                </v-layout>

              </th>
              <td>
                <hh-input placeholder="템플릿 ID" class="input" v-model="item.kakaoCompleteId"
                  :disabled="!item.useKakaoComplete"></hh-input>
                <v-textarea class="input mt-2" placeholder="템플릿" solo flat hide-details v-model="item.kakaoCompleteTmpl"
                  :disabled="!item.useKakaoComplete"></v-textarea>
              </td>
            </tr>

            <tr>
              <th width="120px">등록시 알림톡</th>
              <td>
                <hh-input class="input" placeholder="템플릿 ID" v-model="item.kakaoT1"></hh-input>
                <v-textarea class="input mt-2" placeholder="템플릿" solo flat hide-details
                  v-model="item.kakaoT1tmpl"></v-textarea>
              </td>
            </tr>

            <tr>
              <th>대기팀 알림톡</th>
              <td>
                <hh-input class="input" v-model="item.kakaoT2"></hh-input>
                <v-textarea class="input mt-2" placeholder="템플릿" solo flat hide-details
                  v-model="item.kakaoT2tmpl"></v-textarea>
              </td>
            </tr>
            <tr>
              <th>입장 알림톡</th>
              <td>
                <hh-input class="input" v-model="item.kakaoT3"></hh-input>
                <v-textarea class="input mt-2" placeholder="템플릿" solo flat hide-details
                  v-model="item.kakaoT3tmpl"></v-textarea>
              </td>
            </tr>
          </table>
        </div>
      </div>



      <div class="text-center mt-4">
        <v-btn v-if="$route.params.id != 'create'" color="error" @click="removeItem()">삭제</v-btn>
      </div>
    </div>
    <v-dialog v-model="itemDialog.visible" width="600px">
      <v-card class="pa-4" v-if="itemDialog.item">
        <div class="text-center">
          <image-box class="image-container" @change="changeItemImage" :prefill="getPrefill(itemDialog.item)"></image-box>
        </div>
        <div class="input-header">제목</div>
        <hh-input class="input" v-model="itemDialog.item.title" placeholder="제목"></hh-input>
        <div class="input-header">가격</div>
        <hh-money class="input" v-model="itemDialog.item.price"></hh-money>
        <div class="input-header">설명</div>
        <v-textarea class="input" solo flat hide-details v-model="itemDialog.item.description"
          placeholder="설명"></v-textarea>
        <div class="text-center mt-2">
          <v-btn v-if="itemDialog.item.id" color="primary" depressed @click="itemModify">수정</v-btn>
          <v-btn v-else color="primary" depressed @click="itemSubmit">추가</v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="orderPrinterDialog.visible" width="500px">
      <v-card class="pa-4" v-if="orderPrinterDialog.item">
        <div class="input-header">프린터이름</div>
        <hh-input class="input" v-model="orderPrinterDialog.item.name" placeholder="프린터이름"></hh-input>
        <div class="input-header">포트이름</div>
        <v-select class="input" v-model="orderPrinterDialog.item.portName" :items="portNames" solo flat
          hide-details></v-select>
        <div class="input-header">프린터타입</div>
        <v-select class="input" v-model="orderPrinterDialog.item.printerType" :items="printerType" solo flat
          hide-details></v-select>
        <div class="input-header">
          POS
        </div>
        <v-select class="input" v-model="orderPrinterDialog.item.deviceId" :items="posList" solo flat hide-details
          item-text="displayName" item-value="id"></v-select>
        <div class="text-center mt-2">
          <v-btn color="primary" depressed @click="orderPrinterSubmit">
            <span v-if="orderPrinterDialog.item.id">
              수정
            </span>
            <span v-else>
              추가
            </span>
          </v-btn>

        </div>
      </v-card>

    </v-dialog>
  </div>
</template>

<script>
import hhEditor from "@/components/hhEditor";
import InputFile from "@/components/InputFile";
import TimePicker from "@/components/TimePicker2";
import draggable from "vuedraggable";
import asyncForEach from "async-await-foreach";
import printerType from "@/data/printerType"
import _ from "underscore";
export default {
  components: {
    TimePicker,
    InputFile,
    hhEditor,
    draggable,
  },
  data() {
    return {
      tab: 1,
      printerType,
      storeList: [],
      selectedStore: null,

      categoryList: [],
      monthlyKakao: [],
      item: {
        title: "",
        rating: null,
        zipcode: "",
        address: "",
        imageId: null,
        status: 0,
        addressDetail: "",
        storeDescription: "",
        totalFunding: 0,
        openDate: null,
        coverImages: [],
        images: [],
        investFields: [],
        images: [],
        x: null,
        y: null,
        link: "",
      },
      kioskCoverVideo: null,
      file: null,
      portNames: [
        "COM1",
        "COM2",
        "COM3",
        "COM4",
        "COM5",
        "COM6",
        "COM7",
        "COM8",
        "COM9",

      ],
      posList: [],
      itemDialog: {
        visible: false,
        item: null,
      },
      orderPrinterDialog: {
        visible: false,
        item: null
      }
    };
  },
  filters: {
    printerType(type) {
      return _.chain(printerType)
        .findWhere({
          value: type
        })
        .get("text")
        .value()
    }
  },
  mounted() {
    if (this.$route.params.id != "create") {
      this.getStoreData()
    }
  },
  methods: {
    removeCoverImage(coverImage) {
      if (!confirm("정말 삭제하시겠습니까?")) return;
      if (coverImage.id) {
        coverImage.deleted = true;
      } else {
        this.item.images.splice(this.item.images.indexOf(coverImage), 1);
      }
    },
    changeTableCover(file) {

      this.item.images.push({
        file: file,
        imageId: null,
        visibleStartTime: null,
        visibleEndTime: null,
        hiddenStartTime: null,
        hiddenEndTime: null,
        visibleMon: true,
        visibleTue: true,
        visibleWed: true,
        visibleThu: true,
        visibleFri: true,
        visibleSat: true,
        visibleSun: true,
        hiddenMon: false,
        hiddenTue: false,
        hiddenWed: false,
        hiddenThu: false,
        hiddenFri: false,
        hiddenSat: false,
        hiddenSun: false,
        order: 9999
      })
      console.log(this.item.images)
    },
    removeOrderPrinter(orderPrinter) {
      if (!confirm("정말 삭제하시겠습니까?")) return;
      this.$axios.post("/root/store/orderprinter/remove", {
        id: orderPrinter.id
      }).then((result) => {
        if (result.data.res) {
          this.getStoreData()
        }
      });
    },
    getStoreData() {
      this.$axios
        .post("/root/store/item", {
          id: this.$route.params.id,
        })
        .then((result) => {
          this.item = result.data.item;
          this.posList = result.data.posList;
          this.monthlyKakao = result.data.monthlyKakao;
        });
    },
    orderPrinterSubmit() {
      this.orderPrinterDialog.visible = false;
      this.$axios.post("/root/store/orderprinter/add", {
        storeId: this.$route.params.id,
        orderPrinter: this.orderPrinterDialog.item
      }).then((result) => {
        if (result.data.res) {
          this.orderPrinterDialog.visible = false;
          this.orderPrinterDialog.item = null;
          this.getStoreData()
        }

      });
    },
    openOrderPrinterDialog(item) {

      this.orderPrinterDialog.item = item || {
        name: "",
        portName: "",
        printerType: 0,
      }
      this.orderPrinterDialog.visible = true;
    },

    changeKioskCoverVideo(file) {

      this.kioskCoverVideo = file
    },

    changeCovers(files) {

      this.item.coverImages.push(files)
      // this.item.coverImages = files;
    },
    reorderCategory(list) {

      _.each(list, (item, key) => {
        item.order = key;
      });
      this.$axios.post("/root/category/reorder", {
        list: list,
      });
    },

    reorderItem(list) {

      _.each(list, (item, key) => {
        item.order = key;
      });
      this.$axios.post("/root/category/item/reorder", {
        list: list,
      });
    },
    getPrefill() {
      if (this.item && this.item.image && this.item.image.imageUrl) {
        return this.item.image.imageUrl;
      }
    },
    changeImage(image) {
      this.file = image;
    },
    getPrefill(item) {

      if (item && item.image && item.image.imageUrl) {
        return item.image.imageUrl;
      }
    },
    removeCategoryItem(item) {
      if (!confirm("정말 삭제하시겠습니까?")) return;
      this.$axios
        .post("/root/category/item/remove", {
          id: item.id,
        })
        .then((result) => {
          if (result.data.res) {
            this.getCategoryList();
          }
        });
    },
    changeItemImage(file) {
      this.itemDialog.item.file = file;
    },
    async itemModify() {
      if (this.itemDialog.item.file) {
        var formData = new FormData();
        formData.append("file", this.itemDialog.item.file);
        var { data } = await this.$axios.post("/file/upload", formData);
        this.itemDialog.item.imageId = data.file.id;
      }
      this.$axios
        .post("/root/category/item/modify", this.itemDialog.item)
        .then((result) => {
          this.itemDialog.visible = false;
          this.itemDialog.item = null;
          this.getCategoryList();
        });
    },
    async itemSubmit() {
      if (this.itemDialog.item.file) {
        var formData = new FormData();
        formData.append("file", this.itemDialog.item.file);
        var { data } = await this.$axios.post("/file/upload", formData);
        this.itemDialog.item.imageId = data.file.id;
      }
      await asyncForEach(this.item.images, async (image, key) => {
        if (!image.id) {
          var formData = new FormData();
          formData.append("file", image);
          var { data } = await this.$axios.post("/file/upload", formData);
          this.item.images[key] = data.file;
        }
      });
      this.$axios
        .post("/root/category/item/add", this.itemDialog.item)
        .then((result) => {
          this.itemDialog.visible = false;
          this.itemDialog.item = null;
          this.getCategoryList();
        });
    },
    openItemDialog(category, item) {
      this.itemDialog.item = item || {
        file: null,
        MenuCategoryId: category.id,
        title: "",
        description: "",
        price: null,
      };
      this.itemDialog.visible = true;
    },
    addCategory() {
      var title = window.prompt("카테고리 제목을 입력해주세요");
      if (title) {
        this.$axios
          .post("/root/category/add", {
            title: title,
            storeId: this.$route.params.id,
          })
          .then((result) => {
            this.getCategoryList();
          });
      }
    },
    getCategoryList() {
      this.$axios
        .post("/root/category/list", {
          storeId: this.$route.params.id,
        })
        .then((result) => {
          this.categoryList = result.data.list;
        });
    },
    removeItem() {
      if (!confirm("정말 삭제하시겠습니까?")) return;
      this.$axios
        .post("/root/store/remove", {
          id: this.item.id,
        })
        .then((result) => {
          if (result.data.res) {
            this.$router.go(-1);
          }
        });
    },
    // 투자금이용계획 변경시 총펀딩금액 및
    changeField() {
      var estimateInfraFunding = 0;
      var estimateDepositFunding = 0;
      _.each(this.item.investFields, (field) => {
        if (field.deleted) {
          return;
        }
        if (field.type == 1) {
          estimateInfraFunding += field.value;
        }
        if (field.type == 2) {
          estimateDepositFunding += field.value;
        }
      });
      this.item.estimateInfraFunding = estimateInfraFunding;
      this.item.estimateDepositFunding = estimateDepositFunding;
      this.item.estimateTotalFunding =
        estimateInfraFunding + estimateDepositFunding;
    },
    changeImages(file) {
      this.item.images.push(file);

    },
    removeImage(list, item) {
      if (item.id) {

        item.deleted = true;
      } else {
        list.splice(list.indexOf(item), 1);
      }
    },
    remove(list, item) {
      list.splice(list.indexOf(item), 1);
      if (item.id) {
        item.deleted = true;
      }
    },
    addRelationStore() {
      if (this.selectedStore) {
        this.item.relationStores.push(this.selectedStore);
      }
    },
    getStoreList() {
      this.$axios.post("/root/store/list").then((result) => {
        this.storeList = result.data.list;
      });
    },
    getInvestFields() {
      var retList = [];
      _.each(this.item.investFields, (invest) => {
        if (!invest.deleted) {
          retList.push(invest);
        }
      });
      return retList;
    },
    addInvest() {
      this.item.investFields.push({
        title: "",
        value: null,
      });
    },

    async submit() {
      if (this.file) {
        var formData = new FormData();
        formData.append("file", this.file);
        var { data } = await this.$axios.post("/file/upload", formData);
        this.item.imageId = data.file.id;
      }
      await asyncForEach(this.item.coverImages, async (image, key) => {
        if (!image.id) {
          var formData = new FormData();
          formData.append("file", image);
          var { data } = await this.$axios.post("/file/upload", formData);
          this.item.coverImages[key] = data.file;
        }
      });
      await asyncForEach(this.item.images, async (image, key) => {
        if (!image.id && image.file) {
          var formData = new FormData();
          formData.append("file", image.file);
          var { data } = await this.$axios.post("/file/upload", formData);
          image.imageId = data.file.id;
          delete image.file
        }
      });
      var { data } = await this.$axios.post("/root/store/submit", this.item);

      if (data.res) {
        window.alert("매장정보가 수정되었습니다");
        this.$router.go(-1);
      }
    },
    // async modify() {
    //   if (this.file) {
    //     var formData = new FormData();
    //     formData.append("file", this.file);
    //     var { data } = await this.$axios.post("/file/upload", formData);
    //     this.item.imageId = data.file.id;
    //   }
    //   if (this.kioskCoverVideo) {
    //     var formData = new FormData();
    //     formData.append("file", this.kioskCoverVideo);
    //     var { data } = await this.$axios.post("/file/upload/video", formData);
    //     this.item.kioskCoverVideoId = data.file.id;
    //   }
    //   await asyncForEach(this.item.coverImages, async (image, key) => {
    //     if (!image.id) {
    //       var formData = new FormData();
    //       formData.append("file", image);
    //       var { data } = await this.$axios.post("/file/upload", formData);
    //       this.item.coverImages[key] = data.file;
    //     }
    //   });
    //   await asyncForEach(this.item.images, async (image, key) => {
    //     if (!image.id && image.file) {
    //       var formData = new FormData();
    //       formData.append("file", image.file);
    //       var { data } = await this.$axios.post("/file/upload", formData);
    //       image.imageId = data.file.id;
    //     }
    //   });
    //   var { data } = await this.$axios.post("/root/store/modify", this.item);

    //   if (data.res) {
    //     window.alert("매장정보가 수정되었습니다");
    //     this.$router.go(-1);
    //   }
    // },
    changeFile(file) {
      this.file = file;
    },
    changeAddress(address) {
      this.item.address = address.address;
      this.item.zipcode = address.zipcode;
      this.item.addressDetail = address.addressDetail;
      this.item.x = address.x;
      this.item.y = address.y;
    },
    openAddressDialog() {
      this.addressDialog.visible = true;
    },
  },
};
</script>
<style scoped lang="less">
.root {
  width: 1100px;
}

.form {
  width: 1100px;
  max-width: 100%;
  margin: 0 auto;
}

.table {
  margin-bottom: 80px;
}

.tab-bar {
  background: white;
}

.tab-contents {
  margin-top: 10px;
  background: white;
  padding: 10px;
}

.image-box-container {
  height: 300px;
}

.multi-image-box-container {
  width: 100px;
  height: 100px;
  display: inline-block;

  .image-box {
    display: inline-block;
  }
}

.image-list {
  .image-item {
    display: inline-block;
    width: 100px;
    height: 100px;
    vertical-align: middle;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;

    .close-btn {
      position: absolute;
      top: 0;
      right: 0;
    }

    .image {
      width: 100px;
      height: 100px;
      border-radius: 5px;
    }
  }
}

.field-table {
  border-collapse: collapse;
  width: 100%;
  // td {
  //   border: 0;
  // }
}

.tab {
  border: 1px solid @primary-color;
  display: inline-block;
  margin-top: 20px;

  .tab-item {
    cursor: pointer;
    padding: 10px 20px;
    display: inline-block;

    &.active {
      background: @primary-color;
      color: white;
    }
  }
}

.category-list {
  border-top: 1px solid #eee;

  .category-item {
    background: white;
    border-bottom: 1px solid #eee;
  }

  .item-list {
    margin-left: 50px;
    margin-bottom: 10px;

    .item {
      background: white;
      border-bottom: 1px solid #eee;
    }
  }
}

.image-container {
  width: 150px;
  height: 150px;
}

.imagebox-container {
  width: 150px;
  height: 150px;
}

.image-list {


  .iamge-item {

    display: inline-block;
    width: 100px;
    height: 100px;
    margin-right: 5px;
    position: relative;

    .close-btn {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 99;
    }

    .image {
      width: 100px;
      height: 100px;
      border-radius: 5px;
    }
  }
}

.printer-list {
  .printer-item {
    display: inline-block;
    border-radius: 10px;
    border: 1px solid #eee;
    padding: 5px 20px 10px;
    margin-bottom: 10px;
  }
}

.cover-image {
  margin-bottom: 20px;

  .image {
    width: 150px;
    height: 150px;
  }
}
</style>