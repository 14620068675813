<template>
    <v-layout align-center justify-center v-if="init">
        <div class="form">
            <div class="logo">
                <b>스마트 알바</b>
            </div>
            <hh-input @keyup.enter="login()" placeholder="아이디" dense class="input" v-model="form.id"></hh-input>
            <hh-input @keyup.enter="login()" type="password" placeholder="비밀번호" dense class="input mt-2"
                v-model="form.password"></hh-input>
            <div class="text-center mt-2">
                <v-btn color="primary" text @click="login()">로그인</v-btn>
            </div>
        </div>
    </v-layout>
</template>

<script>
export default {
    data() {
        return {
            init: false,
            form: {
                id: "",
                password: "",
            }
        }
    },
    mounted() {
        this.$axios.post("/pos/user/info")
            .then(result => {
                if (result.data.res) {
                    this.$store.commit("setStoreInfo", result.data.storeInfo)
                    this.$router.push("/pos/main")
                }
                else {
                    this.init = true
                }
            })
    },
    methods: {
        login() {
            this.$axios.post("/pos/login", this.form)
                .then(result => {
                    if (result.data.res) {
                        this.$store.commit("setStoreInfo", result.data.storeInfo)
                        this.$router.push("/pos/main")
                    }
                    else {
                        window.alert(result.data.msg)
                    }
                })
        }
    }
}
</script>

<style scoped lang="less">
.form {
    margin: 20px;
    width: 350px;
    max-width: 100%;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    .logo {
        text-align: center;
        font-size: 25px;
        font-weight: 700;

        color: rgb(136, 136, 136);

        b {
            color: @primary-color;
        }
    }


}
</style>