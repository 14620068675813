<template>
    <div class="dashboard">
        <v-layout>

            <v-flex xs12 md4 class="pa-2">
                <div class="section-box">
                    <div class="header">이번달 결제액</div>
                    <div class="value">{{ totalPayment | number }}원</div>
                    <div class="header">
                        <div>수수료</div>
                        {{ totalPayment * (0.2 / 100) | number }}원
                    </div>
                </div>

            </v-flex>
            <v-flex xs12 md4 class="pa-2">
                <div class="section-box">
                    <div class="header">이번달 결제건수</div>
                    <div class="value">{{ totalOrder | number }}건</div>
                </div>

            </v-flex>
            <v-flex xs12 md4 class="pa-2">
                <div class="section-box">
                    <div class="header">매장수</div>
                    <div class="value">{{ storeCount | number }}개</div>
                </div>
            </v-flex>
        </v-layout>


        <v-layout wrap>
            <v-flex xs12 md4 class="pa-2">
                <div class="section-box">
                    <div class="chart-container" v-if="dailyChartData">
                        <div class="chart-header">최근 30일 결제액 추이</div>
                        <chart :data="dailyChartData"></chart>
                    </div>
                </div>
            </v-flex>
            <v-flex xs12 md4 class="pa-2">
                <div class="section-box">
                    <div class="chart-container" v-if="hourlyChartData">
                        <div class="chart-header">시간대별 결제금액</div>
                        <chart :data="hourlyChartData"></chart>
                    </div>
                </div>
            </v-flex>
            <v-flex xs12 md4 class="pa-2">
                <div class="section-box">
                    <div class="chart-container" v-if="weeklyChartData">
                        <div class="chart-header">요일별 결제금액</div>
                        <chart :data="weeklyChartData"></chart>
                    </div>
                </div>
            </v-flex>
        </v-layout>



        <div class="section-header">매장별 결제액</div>
        <v-layout wrap>
            <v-flex xs12 md4 class="pa-2" v-for="(storePayment, key) in storePayments" :key="key">
                <div class="section-box">
                    <div class="header">{{ storePayment.store.title }}</div>
                    <div class="value">{{ storePayment.totalPrice | number }}원</div>
                </div>
            </v-flex>
        </v-layout>

    </div>
</template>

<script>
import Chart from "@/components/Chart.vue";
export default {
    components: {
        Chart
    },
    data() {
        return {
            totalPayment: 0,
            totalOrder: 0,
            storeCount: 0,
            storePayments: [],
            dailyChartData: null,
            hourlyChartData: null,
            weeklyChartData: null,

        }
    },
    mounted() {
        this.getDashboard()
    },
    methods: {
        getDashboard() {
            this.$axios.post("/root/dashboard")
                .then(result => {
                    if (result.data.res) {
                        console.log(result.data)
                        this.totalPayment = result.data.totalPayment
                        this.totalOrder = result.data.totalOrder
                        this.storeCount = result.data.storeCount
                        this.storePayments = result.data.storePayments

                        this.dailyChartData = {
                            labels: _.map(result.data.dailyPayments, (item) => {
                                return item.date;
                            }),
                            datas: [
                                {
                                    name: "총 결제액",
                                    data: _.map(result.data.dailyPayments, (item) => {
                                        return item.totalPrice;
                                    }),
                                },

                            ],
                        }
                        this.hourlyChartData = {
                            labels: _.map(result.data.hourlyPayments, (item) => {
                                return item.hour;
                            }),
                            datas: [
                                {
                                    name: "총 결제액",
                                    data: _.map(result.data.hourlyPayments, (item) => {
                                        return item.totalPrice;
                                    }),
                                },

                            ],
                        }
                        this.weeklyChartData = {
                            labels: _.map(result.data.weeklyPayments, (item) => {
                                switch (item.day) {
                                    case "0":
                                        return "일요일"
                                    case "1":
                                        return "월요일"
                                    case "2":
                                        return "화요일"
                                    case "3":
                                        return "수요일"
                                    case "4":
                                        return "목요일"
                                    case "5":
                                        return "금요일"
                                    case "6":
                                        return "토요일"
                                }

                            }),
                            datas: [
                                {
                                    name: "총 결제액",
                                    data: _.map(result.data.weeklyPayments, (item) => {
                                        return item.totalPrice;
                                    }),
                                },

                            ],
                        }
                    }
                })
        }
    }
}
</script>

<style scoped lang="less">
.dashboard {
    background: #efefef77;

}

.chart-container {
    min-height: 300px;
    padding: 10px;
    margin-bottom: 20px;

    .chart-header {
        font-size: 18px;
        font-weight: 700;
        color: #333;
        text-align: center;
    }

}

.section-box {
    height: 100%;

    .header {
        font-size: 16px;
        font-weight: 700;
        color: #333;
        text-align: center;
    }

    .value {
        font-size: 30px;
        font-weight: 700;
        color: #333;
        text-align: center;
    }
}

.section-header {
    font-size: 35px;
    font-weight: 700;
    color: #333;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}
</style>7