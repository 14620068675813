<template>
    <v-layout wrap v-if="monthTotal">
        <v-flex xs12>

            <hh-calendar @changeMonth="changeMonth" v-model="filter.month" :scheduleList="scheduleList"></hh-calendar>



        </v-flex>
        <v-flex xs12 class="pa-2">
            <div class="section-box" v-if="incomeByDayChart">
                <div class="header">요일별 평균 매출</div>
                <column-chart :data="incomeByDayChart"></column-chart>
            </div>

        </v-flex>
        <v-flex class="pa-2" xs12 md3>
            <div class="section-box">
                <div class="header">오늘의 매출</div>
                <div class="value">{{ todayTotal.price | number }}원</div>
                <div class="header" v-if="orderTables.length > 0">현재 주문건수</div>
                <div class="value" v-if="orderTables.length > 0">{{ orderTables.length }}건</div>
                <div class="header" v-if="orderTables.length > 0">현재 주문 가격</div>
                <div class="value" v-if="orderTables.length > 0">{{ orderTables | reduceOrderTable | number }}원</div>
                <div class="header" v-if="orderTables.length > 0">예상매출</div>
                <div class="value" v-if="orderTables.length > 0">{{
                    $options.filters.reduceOrderTable(orderTables) + todayTotal.price | number }}원</div>

            </div>
        </v-flex>
        <v-flex class="pa-2" xs12 md3 v-if="orderTables.length > 0">
            <div class="section-box">
                <div class="section-header">매장 주문 현황</div>
                <table class="table">
                    <thead>
                        <tr>
                            <th>테이블</th>
                            <th>주문건수</th>
                            <th>주문가격</th>
                        </tr>
                        <tr class="text-center">
                            <th colspan="3">주문메뉴</th>
                        </tr>
                    </thead>

                    <tbody v-for="(tableOrder, key) in orderTables" :key="key">
                        <tr>
                            <td class="text-center">
                                테이블{{ tableOrder.name }}
                            </td>
                            <td class="text-center">
                                {{ tableOrder | reduceMenuLength }}건
                            </td>
                            <td class="text-right">
                                {{ [tableOrder] | reduceOrderTable | number }}원
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3">
                                {{ tableOrder | tableMenuText }}


                                <div>
                                    첫주문 시간 : {{ getFirstOrderTime(tableOrder) }}
                                </div>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </v-flex>
        <v-flex class="pa-2" xs12 md3>
            <div class="section-box">
                <div class="header">오늘의 주문건수</div>
                <div class="value">{{ todayOrderCount | number }}건</div>
            </div>
        </v-flex>
        <v-flex class="pa-2" xs12 md3>
            <div class="section-box">
                <div class="header">오늘의 주문 top5</div>
                <div v-for="(menu, key) in topMenu" :key="key">
                    <div class="value" v-if="menu.menuItem">{{ menu.menuItem.title }} ({{ menu.count }})</div>
                </div>
            </div>
        </v-flex>
        <v-flex class="pa-2" xs12 md3>
            <div class="section-box">
                <div class="header">이번달 매출</div>
                <div class="value">{{ monthTotal.price | number }}원</div>

            </div>
        </v-flex>
        <v-flex class="pa-2" xs12 md3>
            <div class="section-box">
                <div class="header">이번달 예상매출</div>
                <div class="value">{{ expectTotal | number }}원</div>
                <div class="hint">최근2주치의 데이터로 예상한 매출입니다</div>
            </div>
        </v-flex>


    </v-layout>
</template>

<script>
import moment from "moment";
import _ from "underscore"
import hhCalendar from "@/components/hhCalendar.vue"

export default {
    components: {
        hhCalendar
    },
    data() {
        return {
            todayTotal: null,
            monthTotal: null,
            todayOrderCount: null,
            scheduleList: [],
            incomeByDayChart: null,
            orderTables: [],
            topMenu: [],
            filter: {
                month: moment().format("YYYYMMDD"),
            }

        }
    },
    filters: {
        tableMenuText(value) {
            var menuList = []
            _.each(value.tableOrders, (item) => {
                _.each(item.orders, (item) => {
                    console.log(item)
                    _.each(item.items, (item) => {
                        menuList.push(item)
                    })
                })
            })
            if (menuList.length > 1) {
                return menuList[0].title + " 외 " + (menuList.length - 1) + "개"
            }
            else if (menuList.length == 1) {
                return menuList[0].title
            }
            else {
                return ""

            }

        },
        reduceMenuLength(value) {
            return _.reduce(value.tableOrders, (memo, item) => {
                _.each(item.orders, (item) => {

                    memo += item.items.length
                })
                return memo
            }, 0)
        },
        reduceOrderTable(value) {
            return _.reduce(value, (memo, item) => {

                return memo + _.reduce(item.tableOrders, (memo, item) => {
                    console.log(item.orders)
                    return memo + _.reduce(item.orders, (memo, item) => {
                        console.log(item.totalPrice)
                        return memo + item.totalPrice
                    }, 0)
                }, 0)
            }, 0)
        }
    },
    mounted() {
        this.getDashBoard()

    },
    methods: {
        getFirstOrderTime(tableOrder) {
            if (tableOrder && tableOrder.tableOrders) {
                var order = _.chain(tableOrder.tableOrders).sortBy("createDate").first().value()
                return moment(order.createDate).format("HH:mm")
            }

        },
        changeMonth(date) {
            this.filter.montth = date
            console.log(date)
            this.getMonth()
        },
        getMonth() {
            this.$axios.post("/pos/monthly", this.filter)
                .then(result => {
                    if (result.data.res) {
                        this.scheduleList = []
                        _.each(result.data.monthList, (item) => {
                            this.scheduleList.push({
                                date: moment(item.date).get('date'),
                                price: item.price,
                                count: item.count,
                            })
                        })
                    }

                })
        },
        getDashBoard() {
            this.$axios.post('/pos/dashboard', this.filter).then(res => {
                this.todayTotal = res.data.todayTotal
                this.monthTotal = res.data.monthTotal
                this.orderTables = res.data.orderTables
                this.topMenu = res.data.topMenu
                this.todayOrderCount = res.data.todayOrderCount,
                    this.expectTotal = res.data.expectTotal

                this.incomesByDay = res.data.incomesByDay

                //요일별 매출 차트
                this.incomeByDayChart = {
                    labels: _.map(res.data.avgDay, (item) => {
                        switch (item.day) {
                            case 1:
                                return "일"

                            case 2:
                                return "월"

                            case 3:
                                return "화"

                            case 4:
                                return "수"

                            case 5:
                                return "목"

                            case 6:
                                return "금"

                            case 7:
                                return "토"

                        }

                    }),
                    datas: [
                        {
                            name: "매출",
                            data: _.map(res.data.avgDay, (item) => {
                                return item.price / item.count;
                            }),
                        },
                    ],
                };
                console.log(this.incomeByDayChart)

                this.scheduleList = []
                this.getMonth()

                // this.month = res.data.month
            })

        }
    }
}
</script>

<style scoped lang="less">
.section-box {
    height: 100%;
    box-shadow: 0 !important;
    border: 1px solid #eee;

    .header {
        text-align: center;
        font-size: 20px;
        font-weight: 500;
    }

    .value {
        font-size: 25px;
        text-align: center;
        font-weight: 700;
    }

    .hint {
        font-size: 12px;
        text-align: center;
        font-weight: 500;
        color: #999;
    }
}

thead {
    background: #eee;
}

.holder {
    height: 100%;
    padding: 5px;
}
</style>