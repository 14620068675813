<template>
    <div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    mounted() {
        console.log("???")
    }
}
</script>

<style scoped lang="less"></style>