<template>
    <div>

    </div>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    mounted() {
        // this.$cookies.set("storeId", this.$route.params.storeId, 4 * 60 * 1000)
        // this.$cookies.set("tableId", this.$route.params.tableId, 4 * 60 * 1000)
        this.$store.commit("setOrderStoreId", this.$route.params.storeId)
        this.$store.commit("setOrderTableId", this.$route.params.tableId)
        this.$store.commit("setOrderTableOrderId", null)
        this.$router.replace("/order")
    }
}
</script>

<style scoped lang="less"></style>