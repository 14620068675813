<template>
    <div class="root">
        <div class="section-box">
            <div class="section-header">월별 매출</div>
            <div class="chart-container" v-if="monthChart">
                <column-chart :data="monthChart"></column-chart>
            </div>
        </div>
        <div class="list" v-if="monthList.length > 0">
            <div class="list-header">매출내역</div>
            <div class="item" v-for="(item, key) in monthList" :key="key">
                <v-layout align-center>
                    <v-flex class="month">
                        {{ item.date }}
                    </v-flex>
                    <div class="price">{{ item.price | number }}원</div>
                </v-layout>

            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
export default {
    data() {
        return {
            monthList: [],
            monthChart: null
        }
    },
    mounted() {
        this.getMonthly()
    },
    methods: {
        getMonthly() {
            this.$axios.post("/pos/incomes/monthly")
                .then(result => {
                    if (result.data.res) {
                        console.log(result.data)
                        this.monthList = result.data.monthList
                        this.monthChart = {
                            labels: _.map(result.data.monthList, (item) => {
                                return moment(item.date, "YYYY-MM").format("MM월");
                            }),
                            datas: [
                                {
                                    name: "매출",
                                    data: _.map(result.data.monthList, (item) => {
                                        return item.price;
                                    }),
                                },
                            ],
                        };

                    }
                })
        }
    }
}
</script>


<style scoped lang="less">
.list {
    .list-header {
        font-size: 20px;
        font-weight: 700;
        margin-left: 5px;
        margin-top: 10px;
    }

    .item {
        .month {
            margin-right: 10px;
            font-size: 14px;
            font-weight: 700;
        }


        .price {
            font-size: 18px;
            font-weight: 700;
        }
    }
}
</style>