<template>
  <div class="time-picker">
    <v-layout align-center>
      <div style="width: 80px">
        <v-text-field class="right-text" suffix="시" placeholder="00" v-model="hour" solo flat hide-details ref="hour"
          mask="##" @keyup="updateHour"></v-text-field>
      </div>

      <div style="width: 80px">
        <v-text-field placeholder="00" class="right-text" suffix="분" v-model="minute" ref="minute" solo flat hide-details
          @keyup="updateMinute"></v-text-field>
      </div>
    </v-layout>
  </div>
  <!-- <v-menu
    ref="menu"
    v-model="menu2"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    :readonly="readonly"
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="time"
        solo
        flat
        hide-details
        append-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="menu2"
      v-model="time"
      format="24hr"
      :readonly="readonly"
      full-width
      @click:minute="$refs.menu.save(time)"
    ></v-time-picker>
  </v-menu> -->
</template>
<script>
import moment from "moment";
export default {
  props: ["value"],
  data() {
    return {
      time: null,
      hour: null,
      minute: null,
    };
  },
  watch: {
    value(val) {
      this.init()
    },
  },
  mounted() {
    console.log(this.value);
    if (this.value) {
      this.hour = moment(this.value, "HH:mm").format("HH");
      this.minute = moment(this.value, "HH:mm").format("mm");
    }
  },
  methods: {
    init() {
      if (this.value) {
        this.hour = moment(this.value, "HH:mm").format("HH");
        this.minute = moment(this.value, "HH:mm").format("mm");
      }
      else {
        this.hour = null
        this.minute = null
      }
    },
    updateHour() {
      if (this.hour && this.hour.length == 2) {
        if (Number(this.hour) > 24) {
          this.hour = "24";
        }
        this.$refs.minute.focus();

        if (this.minute && this.minute.length == 2) {
          var time = this.hour + ":" + this.minute;
          this.$emit("input", time);
        }
      }
    },
    updateMinute(e) {
      console.log(e);
      if (!this.minute && e.keyCode == 8) {
        this.hour = null;
        this.minute = null;
        this.$refs.hour.focus();
      }
      if (
        this.hour &&
        this.hour.length == 2 &&
        this.minute &&
        this.minute.length == 2
      ) {
        var time = this.hour + ":" + this.minute;
        this.$emit("input", time);
      }
    },
  },
};
</script>
<style scoped lang="less">
.time-picker {
  display: inline-block;
}
</style>
