<template>
  <div class="main">
    <v-layout align-center class="tool-bar">
      <!-- <v-btn icon @click="drawer = !drawer"><v-icon>mdi-menu</v-icon></v-btn> -->
      <div class="office-name">스마트알바</div>
      <v-spacer></v-spacer>

      <div class="user-name hidden-sm-and-down" v-if="$store.state.teacherInfo">
        {{ $store.state.teacherInfo.name }}님
      </div>
      <v-btn class="ml-4" outlined @click="logout">로그아웃</v-btn>
    </v-layout>

    <div class="body-holder">
      <v-layout class="body-container">
        <div class="menu-container">
          <div class="menu-bar">
            <div class="menu-itemholder" v-for="(item, key) in getItems()" :key="key" v-show="isShowMenu(item)" :class="{
              active: isActiveMain(item),
            }">
              <v-layout align-center v-ripple class="menu-item" @click="moveMenu(item)">
                <div class="mr-2">
                  <v-icon size="18px" class="menu-icon">{{ item.icon }}</v-icon>
                </div>

                <div class="menu-label">
                  {{ item.title }}
                </div>
                <v-spacer></v-spacer>
                <div class="badge" v-if="item.badge > 0">{{ item.badge }}</div>
              </v-layout>
              <div class="submenu-list" v-if="item.child && item.child.length > 0 && isActiveMain(item)">
                <div class="submenu" v-for="(submenu, key) in item.child" :key="key" @click="moveMenu(submenu)" :class="{
                  active: submenu.to == $route.path,
                }">
                  - {{ submenu.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <v-flex class="content-container">
          <router-view></router-view>
        </v-flex>
      </v-layout>
    </div>

    <div class="loading-container" v-if="$store.state.showLoading">
      <v-layout fill-height align-center justify-center>
        <div class="loading-contents">
          <v-progress-circular indeterminate size="60" class="mb-2" color="primary"></v-progress-circular>
          <div class="label">데이터를 가져오는중입니다</div>
        </div>
      </v-layout>
    </div>
  </div>
</template>

<script>
import _ from "underscore";
import moment from "moment";

export default {
  layout: "admin",
  data() {
    return {
      alarmList: [],
      items: [
        {
          icon: "mdi-home-outline",
          title: "통계",
          to: "/root/dashboard",
          // auth: [1, 2, 5, 6],
        },
        {
          icon: "mdi-home-outline",
          title: "매장관리",
          to: "/root/store",
          // auth: [1, 2, 5, 6],
        },
        {
          icon: "mdi-calendar",
          title: "메뉴관리",
          to: "/root/menu",
          // auth: [1, 2, 5, 6, 10],
        },
        {
          icon: "mdi-calendar",
          title: "기기관리",
          to: "/root/device",
          // auth: [1, 2, 5, 6, 10],
        },
        {
          icon: "mdi-calendar",
          title: "테이블오더",
          to: "/root/tableorder",
          // auth: [1, 2, 5, 6, 10],
        },
        {
          icon: "mdi-calendar",
          title: "테이블관리",
          to: "/root/tablemanage",
          // auth: [1, 2, 5, 6, 10],
        },

      ],
    };
  },
  filters: {
    date(value, format) {
      return moment(value).format(format);
    },
  },
  mounted() {
    this.getUserInfo()
    // this.$axios.post("/main").then((result) => {
    //   if (result.data.res) {
    //     this.$store.commit("setTeacherList", result.data.teacherList);
    //     this.$store.commit("setStudentList", result.data.studentList);
    //     this.$store.commit("setDepartmentList", result.data.departmentList);
    //   }
    // });
    // this.getTeacherInfo();
    // try {
    //   if (this.$messaging) {
    //     this.$messaging.onMessage((payload) => {
    //       this.alarmList.push(payload.data);
    //     });
    //   }
    // } catch (e) {
    //   console.log(e);
    // }

    // //알람 권한요청하기
    // this.$messaging.requestPermission().then(() => {
    //   this.$messaging.getToken().then((token) => {
    //     this.$axios.post("/token/update", { token });
    //   });
    // });
  },
  methods: {
    isActiveMain(item) {
      if (item.to == "/main/" && item.to == this.$route.path) {
        return true;
      }
      if (item.to != "/main/" && this.$route.path.indexOf(item.to) > -1) {
        return true;
      }
      if (item.child && item.child.length > 0) {
        return _.find(item.child, (submenu) => {
          return submenu.to == this.$route.path;
        });
      }
    },

    logout() {
      this.$axios.post("/logout").then((result) => {
        if (result.data.res) {
          this.$store.commit("logout")
          this.$router.replace("/login");
        }
      });
    },
    isShowMenu(menu) {
      if (this.$store.state.userInfo) {
        if (this.$store.state.userInfo.auth == 100) {
          return true;
        }

        if (
          menu &&
          menu.auth &&
          menu.auth.indexOf(this.$store.state.userInfo.auth) > -1
        ) {
          return true;
        }
      }
      return false;
    },
    getUserInfo() {
      this.$axios.post("/user/info").then((result) => {
        if (result.data.res) {
          console.log(result.data)

          this.$store.commit("setUserInfo", result.data.user);
          if (this.$route.path == "/root/" || this.$route.path == "/root") {
            this.$router.replace(_.first(this.getItems()).to);
          }
        } else {
          this.$router.replace("/login");
        }
      });
    },
    getItems() {
      return this.items;
    },
    moveMenu(menu) {

      if (menu.to == this.$route.path) {
        return
      }
      this.$router.push(menu.to);
    },
    getAlarmMessage(alarm) {
      if (alarm.type == 1) {
        return "출석을 확인해주세요";
      }
    },
  },
};
</script>
<style scoped lang="less">
@toolbar-height: 70px;
@menu-width: 200px;

.main {

  height: 100%;
}

.tool-bar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: @toolbar-height;
  background: white;
  border-bottom: 1px solid #cbcbcb;
  padding: 0 27px;
  z-index: 99;

  .office-name {
    font-size: 18px;
    line-height: @toolbar-height;
    color: @primary-color;
    font-weight: bold;
  }

  .time {
    font-size: 18px;
    line-height: @toolbar-height;
    margin-right: 20px;
  }

  .user-name {
    font-size: 18px;
    line-height: @toolbar-height;
    cursor: pointer;

    &:hover {
      color: @primary-color;
    }
  }
}

.body-holder {
  top: @toolbar-height;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.body-container {
  // margin-top: @toolbar-height;
  position: relative;
  width: 100%;
  height: 100%;
}

.menu-container {
  width: @menu-width;
  border-right: 1px solid #efefef;
  position: fixed;
  height: 100%;
  // background: #00b5ad;
  background: white;

  display: inline-block;
  z-index: 99;

  .menu-bar {
    overflow-y: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding-bottom: 80px;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    &::-webkit-scrollbar {
      display: none;
      /* Chrome, Safari, Opera*/
    }
  }

  .menu-itemholder {
    padding: 5px 10px;
    cursor: pointer;

    &.active {
      .menu-item {
        background: @primary-color;

        .menu-icon {
          color: white;
        }

        .menu-label {
          color: white;
          font-weight: bold;
        }
      }
    }

    .menu-item {
      padding: 10px;
      border-radius: 10px;
      text-decoration: none;
      cursor: pointer;

      .menu-icon {
        margin-right: 10px;
        color: black;
      }

      .menu-label {
        display: inline-block !important;
        font-size: 13px !important;
      }

      .badge {
        width: 35px;
        background: white;
        color: black;
      }
    }

    .submenu-list {
      padding-left: 30px;

      .submenu {
        font-size: 13px !important;
        padding: 10px;
        border-radius: 10px;
        cursor: pointer;

        &.active {
          // background: @primary-color;
          font-weight: bold;
          color: @primary-color;
        }
      }
    }
  }
}

.content-container {
  margin-left: @menu-width;
  // padding: 10px;
}

.menu {
  top: 60px !important;
}

.dialog-contents {
  background: white;
  padding: 20px;
}

.alarm-list {
  width: 450px;
  max-height: 450px;
  overflow: auto;

  .alarm-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    font-size: 16px;
    background: #fff8c5;

    .text-container {}

    .date-container {
      width: 100px;
      min-width: 100px;
      color: #999;
    }

    &.read {
      background: white !important;
    }
  }
}

.drawer-dialog {
  .dialog-contents {
    background: #0db172;
    width: 100%;
    height: 100%;
    padding: 0;

    .close-container {
      .close-btn {
        left: 15px;
      }
    }

    .menu-list {
      padding-left: 30px;
      margin-top: 30px;

      .menu-icon {
        width: 30px;
        height: 30px;
        vertical-align: middle;
        margin-right: 10px;
      }

      .menu-item {
        color: white;
        font-weight: bold;
        font-size: 15px;
        margin-bottom: 30px;
        display: block;
        text-decoration: none;
        cursor: pointer;

        .title {
          display: inline-block;
        }
      }
    }

    .login-info {
      background: white;
      padding: 16px;

      .profile-img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .blank-user {
      display: inline-block;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: #ffb8a8;
    }

    .name-container {
      padding: 16px;
      color: #5a5a5a;
      font-size: 14px;
      font-weight: bold;
    }
  }
}

.notice {
  color: red;
  font-weight: bold;
}

.loading-dialog {
  padding: 20px 40px;
  background: white;
  border-radius: 40px;
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;

  .loading-contents {
    width: 300px;
    padding: 20px;
    background: white;
    border-radius: 30px;
    text-align: center;
    box-shadow: 0 6px 10px #eee;
    border: 1px solid #eee;

    .label {
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 920px) {
  .content {
    margin-left: 0;
  }
}
</style>
