<template>
    <v-layout align-center>
        <div class="minus-btn" @click="minus()">
            <v-icon size="30px">mdi-minus</v-icon>
        </div>
        <v-flex class="count">
            {{ value }}
        </v-flex>
        <div class="plus-btn" @click="plus()">
            <v-icon size="30px">mdi-plus</v-icon>
        </div>
    </v-layout>
</template>

<script>
export default {
    props: ["value", "minimum"],
    data() {
        return {
        }
    },
    methods: {
        clickSound() {
            this.$emit("clickSound")
        },
        minus() {
            this.clickSound()
            if (this.value < 0) {
                return;
            }
            if (this.minimum && this.value <= this.minimum) {
                return;
            }
            this.$emit('input', this.value - 1)
        },
        plus() {
            this.clickSound()
            this.$emit('input', this.value + 1)
        }
    }
}
</script>

<style scoped lang="less">
.minus-btn,
.plus-btn {
    background: #eee;
    padding: 5px;
    font-size: 20px;
}

.count {
    font-size: 30px;
    text-align: center;

}
</style>