<template>
  <v-text-field solo flat hide-details :class="cls" :placeholder="placeholder" :disabled="disabled" v-model="tmpValue"
    :type="type" :suffix="suffix" @keyup="changeValue" :readonly="readonly" :dense="dense"></v-text-field>
</template>

<script>
export default {
  props: ["cls", "value", "type", "placeholder", "suffix", "readonly", "dense", "disabled"],
  data() {
    return {
      tmpValue: null,
    };
  },
  watch: {
    value(val) {
      this.change(val);
    },
  },
  mounted() {
    this.tmpValue = this.value;
    this.$emit("input", this.tmpValue);
  },
  methods: {
    changeValue(event) {
      this.$emit("input", this.tmpValue);
      this.$emit("keyup", event);
    },
    change(val) {
      this.tmpValue = val;
      // this.$emit("input", this.tmpValue);
    },
  },
};
</script>

<style></style>