<template>
  <div class="main">
    <v-layout align-center class="tool-bar">
      <v-btn class="hidden-md-and-up" icon @click="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <div class="office-name" v-if="$store.state.storeInfo">
        {{ $store.state.storeInfo.title }}
      </div>
      <v-spacer></v-spacer>

      <div class="user-name hidden-sm-and-down" v-if="$store.state.employee">
        {{ $store.state.employee.name }}님
      </div>
      <v-btn class="ml-4" text @click="logout">로그아웃</v-btn>
    </v-layout>
    <v-navigation-drawer class="drawer" v-model="drawer" left fixed temporary>
      <div class="text-right">
        <v-btn icon @click="drawer = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="drawer-list">
        <div class="menu-itemholder" v-for="(item, key) in getItems()" :key="key" v-show="isShowMenu(item)" :class="{
          active: isActiveMain(item),
        }">
          <v-layout align-center v-ripple class="menu-item" @click="moveMenu(item)">
            <v-icon class="menu-icon">{{ item.icon }}</v-icon>
            <div class="menu-label">
              {{ item.title }}
            </div>
            <v-spacer></v-spacer>
            <div class="badge" v-if="item.badge > 0">{{ item.badge }}</div>
          </v-layout>
          <div class="submenu-list">
            <div class="submenu" v-for="(submenu, key) in item.child" :key="key" @click="moveMenu(submenu)" :class="{
              active: submenu.to == $route.path,
            }">
              - {{ submenu.title }}
            </div>
          </div>
        </div>

      </div>

    </v-navigation-drawer>

    <div class="body-holder">
      <v-layout class="body-container">
        <div class="menu-container">
          <div class="menu-bar">
            <div class="menu-itemholder" v-for="(item, key) in getItems()" :key="key" v-show="isShowMenu(item)" :class="{
              active: isActiveMain(item),
            }">
              <v-layout align-center v-ripple class="menu-item" @click="moveMenu(item)">
                <div class="mr-2">
                  <v-icon class="menu-icon">{{ item.icon }}</v-icon>
                </div>

                <div class="menu-label">
                  {{ item.title }}
                </div>
                <v-spacer></v-spacer>
                <div class="badge" v-if="item.badge > 0">{{ item.badge }}</div>
              </v-layout>
              <div class="submenu-list" v-if="item.child && item.child.length > 0 && isActiveMain(item)">
                <div class="submenu" v-for="(submenu, key) in item.child" :key="key" @click="moveMenu(submenu)" :class="{
                  active: submenu.to == $route.path,
                }">
                  - {{ submenu.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <v-flex class="content-container">
          <router-view></router-view>
        </v-flex>
      </v-layout>
    </div>

    <div class="loading-container" v-if="$store.state.showLoading">
      <v-layout fill-height align-center justify-center>
        <div class="loading-contents">
          <v-progress-circular indeterminate size="60" class="mb-2" color="primary"></v-progress-circular>
          <div class="label">데이터를 가져오는중입니다</div>
        </div>
      </v-layout>
    </div>
  </div>
</template>

<script>
import _ from "underscore";
import moment from "moment";

export default {

  data() {
    return {
      drawer: false,
      alarmList: [],
      items: [
        {
          icon: "mdi-home-outline",
          title: "매장정보관리",
          to: "/pos/main/store/dashboard/",

          child: [{
            title: "대시보드",
            to: "/pos/main/store/dashboard/"
          }, {
            title: "매장정보",
            to: "/pos/main/store/info/"
          }]
        },
        {
          icon: "mdi-pasta",
          title: "메뉴관리",
          to: "/pos/main/menu/item/",
          child: [
            {
              title: "메뉴관리",
              to: "/pos/main/menu/item/"
            },
            {
              title: "카테고리관리",
              to: "/pos/main/menu/category/"
            },

            {
              title: "옵션관리",
              to: "/pos/main/menu/option/"
            }
          ],

        },
        {
          icon: "mdi-finance",
          title: "매출관리",
          to: "/pos/main/incomes/daily",
          child: [
            {
              title: "일자별 매출",
              to: "/pos/main/incomes/daily"
            },
            {
              title: "메뉴별 매출",
              to: "/pos/main/incomes/menu"
            },
            {
              title: "시간대별 매출",
              to: "/pos/main/incomes/time"
            },
            {
              title: "월별 매출",
              to: "/pos/main/incomes/monthly"
            }, {
              title: "결제수단별 매출",
              to: "/pos/main/incomes/method"
            },
          ],

        },

      ],
    };
  },
  filters: {
    date(value, format) {
      return moment(value).format(format);
    },
  },
  mounted() {
    this.getUserInfo()
  },
  methods: {
    isActiveMain(item) {
      if (item.to == "/main/" && item.to == this.$route.path) {
        return true;
      }
      if (item.to != "/main/" && this.$route.path.indexOf(item.to) > -1) {
        return true;
      }
      if (item.child && item.child.length > 0) {
        return _.find(item.child, (submenu) => {
          return submenu.to == this.$route.path;
        });
      }
    },

    logout() {
      this.$axios.post("/logout").then((result) => {
        if (result.data.res) {
          this.$store.commit("setStoreInfo", null);
          this.$router.replace("/pos");
        }
      });
    },
    isShowMenu(menu) {
      return true;
      if (this.$store.state.employee) {
        //운영자는 전체메뉴가보임
        if (this.$store.state.employee.position == 1000) {
          return true;
        }

        if (
          menu &&
          menu.position &&
          menu.position.indexOf(this.$store.state.employee.position) > -1
        ) {

        }
      }
      return false;
    },
    getUserInfo() {
      this.$axios.post("/pos/user/info").then((result) => {
        if (result.data.res) {
          this.$store.commit("setStoreInfo", result.data.store);
          if (this.$route.path == "/pos/main/" || this.$route.path == "/pos/main") {
            console.log(_.first(this.getItems()))
            this.$router.replace(_.first(this.getItems()).to);
          }
        } else {
          this.$router.replace("/");
        }
      });
    },
    getItems() {
      return this.items;
    },
    moveMenu(menu) {
      if (menu.to == this.$route.path) {
        return;
      }
      this.$router.push(menu.to);
    },

  },
};
</script>
<style scoped lang="less">
@toolbar-height: 70px;
@menu-width: 200px;

.main {

  height: 100%;
}

.tool-bar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: @toolbar-height;
  background: white;
  border-bottom: 1px solid #cbcbcb;
  padding: 0 27px;
  z-index: 5;

  .office-name {
    font-size: 18px;
    // line-height: @toolbar-height;
    color: @primary-color;
    font-weight: bold;
  }

  .time {
    font-size: 18px;
    line-height: @toolbar-height;
    margin-right: 20px;
  }

  .user-name {
    font-size: 18px;
    font-weight: 500;
    line-height: @toolbar-height;
    cursor: pointer;

    &:hover {
      color: @primary-color;
    }
  }
}

.body-holder {
  top: @toolbar-height;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.body-container {
  // margin-top: @toolbar-height;
  position: relative;
  width: 100%;
  height: 100%;
}

.menu-container {
  width: @menu-width;
  border-right: 1px solid #efefef;
  position: fixed;
  height: 100%;
  // background: #00b5ad;
  background: white;

  display: inline-block;
  z-index: 10;

  .menu-bar {
    overflow-y: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding-bottom: 80px;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    &::-webkit-scrollbar {
      display: none;
      /* Chrome, Safari, Opera*/
    }
  }


}

.menu-itemholder {
  padding: 5px 10px;

  &.active {
    .menu-item {
      background: @primary-color;

      .menu-icon {
        color: white;
      }

      .menu-label {
        color: white;

      }
    }
  }

  .menu-item {
    padding: 10px;
    border-radius: 10px;
    text-decoration: none;
    cursor: pointer;

    .menu-icon {
      // margin-right: 10px;
      color: black;
    }

    .menu-label {
      // display: inline-block !important;
      font-size: 18px !important;
      font-weight: 700;
    }

    .badge {
      width: 35px;
      background: white;
      color: black;
    }
  }

  .submenu-list {
    padding-left: 20px;

    .submenu {
      font-size: 16px !important;
      padding: 5px 10px;
      font-weight: 500;
      border-radius: 10px;
      cursor: pointer;

      &.active {
        // background: @primary-color;
        font-weight: bold;
        color: @primary-color;
      }
    }
  }
}

.content-container {
  margin-left: @menu-width;
  padding: 10px;
}




.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9;

  .loading-contents {
    width: 300px;
    padding: 20px;
    background: white;
    border-radius: 30px;
    text-align: center;
    box-shadow: 0 6px 10px #eee;
    border: 1px solid #eee;

    .label {
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 920px) {
  .tool-bar {
    padding: 0 5px;
  }

  .content {
    margin-left: 0;
  }

  .menu-container {
    display: none;
  }

  .content-container {
    margin-left: 0;
    padding: 5px;
  }
}
</style>
