<template>
  <v-layout v-if="init" align-center justify-center class="background">
    <div class="inner-container">
      <div class="login-form">
        <div class="logo"><b>스마트 알바</b></div>
        <div class="slog">스마트한 매장관리</div>
        <v-text-field solo flat hide-details class="input" name="email" @keyup.enter="login" v-model="form.email"
          placeholder="이메일 주소"></v-text-field>
        <v-text-field solo flat hide-details class="input mt-2" name="password" type="password" @keyup.enter="login"
          v-model="form.password" placeholder="비밀번호"></v-text-field>
        <div class="text-center">
          <v-btn depressed x-large block class="mt-2 mb-2 login-btn" color="#888" dark @click="login">
            로그인</v-btn>
          <v-btn depressed x-large block class="mt-6 mb-6 login-btn" color="#FEE500" @click="kakaoLogin('/admin')">카카오
            아이디로 로그인</v-btn>
          <!-- <v-btn outlined color="primary" @click="moveJoin">회원가입</v-btn> -->
        </div>
      </div>
    </div>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      init: false,
      form: {
        email: "",
        password: "",
      },
    };
  },
  mounted() {
    this.$axios.post("/user/info").then((result) => {
      if (result.data.res) {
        this.$router.push("/root");
        this.$store.commit("setUserInfo", result.data.user);
      } else {
        this.init = true;
      }
    });
  },
  methods: {
    moveJoin() {
      this.$router.push("/join");
    },
    login() {
      this.$axios.post("/login", this.form).then((result) => {
        //서버에서 로그인이 성공하면 /admin으로 가게하기

        if (result.data.res) {
          this.$store.commit("setUserInfo", result.data.user);
          this.$router.push("/admin");
        } else {
          window.alert(result.data.msg);
          return;
        }
      });
    },
    kakaoLogin(path) {
      path = path || "/";
      let redierctUrl = `${location.protocol}//${location.host}/api/v1/kakao/authorize`


      Kakao.Auth.authorize({
        redirectUri: redierctUrl,
        scope: "profile_nickname,profile_image,account_email",
        state: `${encodeURIComponent(path)}`,
      });
    },
  },
};
</script>
<style scoped lang="less">
.background {
  background-color: #fff;
}

.inner-container {
  width: 450px;
  max-width: 100%;
  padding: 40px;

  .login-form {
    background: white;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 100px;
    // box-shadow: 0 3px 6px #aaa;

    .logo {
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      color: #888;

      b {
        color: @primary-color;
      }
    }

    .slog {
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      color: #888;
      margin-bottom: 20px;
    }

  }
}
</style>
