<template>
  <div>
    <div class="app-header" @mouseleave="menuUnexpend" :class="{ white: !$store.state.landingTop }">
      <transition>
        <div class="inner-container">
          <v-layout align-center class="hidden-sm-and-down">
            <router-link to="/" class="logo">
              Store Manager
              <!-- <img src="/img/logo.png" alt="" -->
            </router-link>
            <!-- <div
              @mouseenter="menuExpend"
              class="menu-btn"
              color="white"
              depressed
            >
              <router-link to="/company"> 서비스소개 </router-link>
            </div> -->
            <!-- <div
              @mouseenter="menuExpend"
              class="menu-btn"
              color="white"
              depressed
            >
              <router-link to="/stores"> 매장소개 </router-link>
            </div>
            <div
              @mouseenter="menuExpend"
              class="menu-btn"
              color="white"
              depressed
            >
              <router-link to="/news"> 매장소식 </router-link>
            </div>
            <div
              @mouseenter="menuExpend"
              class="menu-btn"
              color="white"
              depressed
            >
              <router-link to="/faq"> FAQ </router-link>
            </div> -->

            <v-spacer></v-spacer>
            <v-btn v-if="$store.state.userInfo && $store.state.userInfo.auth == 100" text @click="moveRoot">관리자페이지</v-btn>
            <v-btn v-if="$store.state.userInfo" depressed color="white" @click="goAdmin"
              class="hidden-sm-and-down ml-4 mr-4">
              매장관리
            </v-btn>

            <v-btn v-if="$store.state.userInfo" depressed color="white" @click="logout"
              class="hidden-sm-and-down ml-4 mr-4">
              로그아웃
            </v-btn>
            <!-- <v-btn
              v-if="!$store.state.userInfo && $store.state.landingTop"
              outlined
              color="#fae000"
              @click="kakaoLogin()"
              class="hidden-sm-and-down ml-4 mr-4"
            >
              카카오아이디 로그인
            </v-btn> -->
            <!-- <v-btn
              v-if="!$store.state.userInfo && !$store.state.landingTop"
              depressed
              rounded
              color="#fae000"
              @click="kakaoLogin()"
              class="hidden-sm-and-down ml-4 mr-4"
            >
              카카오아이디 로그인
            </v-btn> -->
            <!-- <div
              class="login-btn"
              v-if="!$store.state.userInfo"
              @click="kakaoLogin"
            >
              <v-layout align-center>
                <img src="/img/ic_kakao.png" alt="" />
                <div class="label">로그인</div>
              </v-layout>
            </div> -->
            <v-btn text v-if="!$store.state.userInfo" @click="moveLogin">로그인</v-btn>
          </v-layout>

          <v-layout v-if="expend">
            <div class="logo"></div>
            <div class="menu-list">
              <div class="menu-item">
                <router-link to="/company">회사소개</router-link>
              </div>
              <div class="menu-item">
                <router-link to="/useage">이용방법</router-link>
              </div>
              <div class="menu-item">
                <router-link to="/strategy">투자 전략 </router-link>
              </div>
            </div>
          </v-layout>
        </div>
      </transition>

      <div class="hidden-md-and-up">
        <v-layout align-center>
          <!-- <div class="pa-2" @click="drawer = !drawer">
            <v-icon v-if="$store.state.landingTop" color="white"
              >mdi-menu</v-icon
            >
            <v-icon v-else>mdi-menu</v-icon>
          </div> -->
          <router-link to="/" class="logo">
            Store Manager
            <!-- <img src="/img/logo.png" alt="" -->
          </router-link>
          <v-spacer></v-spacer>

          <!-- <div
            class="login-btn"
            v-if="!$store.state.userInfo"
            @click="kakaoLogin"
          >
            <v-layout align-center>
              <img src="/img/ic_kakao.png" alt="" />
              <div class="label">로그인</div>
            </v-layout>
          </div> -->
          <v-btn v-if="!$store.state.userInfo" text @click="moveLogin">로그인</v-btn>
          <v-btn v-if="$store.state.userInfo && $store.state.userInfo.auth == 100" text @click="moveLogin">관리자페이지</v-btn>

          <v-btn v-if="$store.state.userInfo" text @click="goAdmin">
            매장관리
          </v-btn>
        </v-layout>
      </div>
    </div>
    <v-navigation-drawer v-model="drawer" left fixed temporary>
      <div class="text-right">
        <v-btn icon @click="drawer = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-list dense>
        <v-list-item link>
          <v-list-item-content @click="move('/stores')">
            <v-list-item-title class="menu-item">매장소개</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link>
          <v-list-item-content @click="move('/faq')">
            <v-list-item-title class="menu-item">FAQ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link v-if="!$store.state.userInfo">
          <v-list-item-content @click="moveLogin">
            <v-list-item-title class="menu-item">로그인</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link v-if="$store.state.userInfo">
          <v-list-item-content @click="goMypage">
            <v-list-item-title class="menu-item">마이페이지</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link v-if="$store.state.userInfo">
          <v-list-item-content @click="logout">
            <v-list-item-title class="menu-item">로그아웃</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <div class="blank" v-if="!isLanding"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      init: false,
      expend: false,
      drawer: false,
      isLanding: false,
    };
  },
  watch: {
    "$store.state.loginCallCount": function () {
      this.kakaoLogin();
    },
    "$route.path": function () {
      if (this.$route.path == "/") {
        this.isLanding = true;
        this.expend = false;
      } else {
        this.isLanding = false;
        this.$store.commit("setLandingTop", false);
      }
    },
  },

  mounted() {
    if (this.$route.path == "/") {
      this.isLanding = true;
    }
    this.getUserInfo();
  },
  methods: {
    moveRoot() {
      this.$router.push("/root");
    },
    moveLogin() {
      this.$router.push("/login");
    },
    menuExpend() {
      // if (!this.$store.state.landingTop) {
      //   this.expend = true;
      // }
    },
    menuUnexpend() {
      this.expend = false;
    },
    move(path) {
      this.drawer = false;
      this.$router.push(path);
    },
    goManager() {
      this.$router.push("/manager");
    },
    goAdmin() {
      this.$router.push("/admin");
    },
    goMypage() {
      this.$router.push("/mypage");
    },
    logout() {
      this.$axios.post("/user/logout").then((result) => {
        if (result.data.res) {
          this.$store.commit("logout");
          if (this.$route.path != "/") {
            this.$router.replace("/");
          }

        }
      });
    },
    getUserInfo() {
      this.$axios.post("/user/info").then((result) => {
        this.init = true;
        if (result.data.res) {
          this.$store.commit("setUserInfo", result.data.user);
          var pendingFunding = result.data.pendingFunding;
          if (pendingFunding) {
            if (
              !window.confirm(
                "아직 완료되지 않은 투자가 있습니다 확인하시겠습니까?"
              )
            ) {
              return;
            }
            this.$router.push(`/store/funding/contract/${pendingFunding.id}`);
          }
        }
      });
    },
    kakaoLogin(path) {
      path = this.$store.state.loginRedirect || path || "/";
      Kakao.Auth.authorize({
        redirectUri: `${location.protocol}//${location.host}/api/v1/kakao/authorize`,
        scope: "profile_nickname,profile_image,account_email",
        state: `${encodeURIComponent(path)}`,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.app-header {
  padding: 20px 40px;
  position: fixed;

  top: 0;
  width: 100%;
  max-width: 100%;
  z-index: 6;

  &.white {
    background: white;
    box-shadow: 0px 0px 6px #ddd;

    .menu-btn {
      cursor: pointer;

      a {
        color: black;
      }
    }
  }

  .inner-container {
    width: @inner-width;
    margin: 0 auto;
    max-width: 100%;
  }

  .menu-btn {
    font-size: 16px;
    font-weight: bold;
    margin-left: 40px;
    margin-right: 40px;
    color: black;
    text-align: center;

    a {
      color: white;
    }

    &:hover {
      color: @primary-color;

      a {
        color: @primary-color;
      }
    }
  }

  .menu-list {
    .menu-item {
      cursor: pointer;
      margin-left: 40px;
      margin-right: 40px;
      margin-top: 20px;
      margin-bottom: 20px;
      text-align: center;

      &:hover {
        color: @primary-color;

        a {
          color: @primary-color;
        }
      }
    }
  }

  a {
    color: black;
    text-decoration: none;
  }

  .logo {
    font-size: 20px;
    font-weight: bold;
    color: @primary-color;
    vertical-align: middle;

    // height: 30px;
    img {
      height: 100%;
    }
  }
}

.blank {
  height: 76px;
}

.text-white {
  color: white !important;
}

.login-btn {
  border-radius: 20px;
  //box-shadow: 3px 3px 10px #555;

  background: #fae000;
  transition-duration: 0.3s;
  // width: 60px;
  // height: 60px;
  cursor: pointer;
  padding: 10px 15px;
  z-index: 100;

  &:hover {
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
  }

  &:active {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
  }

  .label {
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
  }

  img {
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 680px) {
  .app-header {
    padding: 10px 15px;

    .menu-item {
      font-size: 16px !important;
    }

    .logo {

      // height: 25px;
      img {
        height: 100%;
      }
    }
  }

  .blank {
    height: 62px;
  }
}
</style>