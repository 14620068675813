<template>
    <div class="root">
        <div class="section-box">
            <div class="section-header">기기관리</div>
            <v-layout align-center class="mt-2 mb-2">
                <div class="mr-2" style="width:300px;">
                    <v-select placeholder="매장명" class="input" @change="changeFilter" v-model="filter.storeId" dense solo
                        flat hide-details :items="stores" item-value="id" item-text="title" clearable></v-select>
                </div>
                <div>
                    <hh-input v-model="filter.deviceId" class="input" placeholder="기기id" :dense="true"
                        @keyup="changeFilter"></hh-input>
                </div>
                <div class="ml-2" style="width:200px;">
                    <v-select placeholder="기기타입" class="input" @change="changeFilter" v-model="filter.deviceType" dense solo
                        flat hide-details :items="deviceType" clearable></v-select>
                </div>

            </v-layout>
            <table class="table">

                <thead>
                    <tr>
                        <th width="50px">No</th>
                        <th width="180px">이름</th>
                        <th>매장</th>
                        <th width="150px">제조사</th>
                        <th width="130px">기기타입</th>
                        <!-- <th>PC이름</th> -->
                        <!-- <th width="80px">앱이름</th> -->
                        <th width="80px">버전</th>
                        <th width="360px">기기ID</th>
                        <th width="150px">최종로그인</th>
                        <th width="150px">생성일</th>
                        <th width="160px"></th>
                    </tr>

                </thead>
                <tbody>
                    <tr v-for="(device, key) in devices" :key="key" :class="{ 'text-green': getDeviceGreen(device) }">
                        <td class="text-center">
                            {{ nav.startViewNum - key }}
                        </td>
                        <td>
                            <hh-input dense v-model="device.name"></hh-input>
                        </td>

                        <td>
                            <v-autocomplete v-model="device.storeId" item-value="id" item-text="title" :items="stores" solo
                                flat hide-details dense></v-autocomplete>
                        </td>
                        <td>
                            <v-select solo flat hide-details :items="vendor" v-model="device.vendor"></v-select>
                        </td>
                        <td class="text-center">
                            {{ device.type | deviceType }}
                            <span v-if="device.table">({{ device.table.name }})</span>
                        </td>
                        <!-- <td class="text-center">
                            {{ device.deviceName }}
                        </td> -->
                        <!-- <td class="text-center">
                            {{ device.appName }}
                        </td> -->

                        <td class="text-center">
                            {{ device.version }}
                            <span v-if="device.frontVersion">({{ device.frontVersion }})</span>
                        </td>

                        <td>
                            {{ device.deviceId }}

                        </td>
                        <td class="text-center">
                            {{ device.lastLoginDate | date("YYYY-MM-DD HH:mm") }}
                        </td>
                        <td class="text-center">
                            {{ device.createDate | date("YYYY-MM-DD") }}
                        </td>
                        <td class="text-center">
                            <v-btn color="primary" text @click="modify(device)">수정</v-btn>
                            <v-btn color="error" text @click="remove(device)">삭제</v-btn>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="mt-2">
                <pagenation :nav="nav" v-if="nav" :page="filter.page" @movePage="movePage"></pagenation>
            </div>


        </div>
    </div>
</template>

<script>

import deviceType from "@/data/deviceType"
export default {
    data() {
        return {
            deviceType,
            filter: {
                page: 1,
                deviceId: null,
                storeId: null,
                deviceType: null
            },
            stores: [],
            devices: [],
            nav: null,
            filterChangePromise: null
        }
    },
    mounted() {
        this.getDevices()
        this.getStores()
    },
    methods: {
        changeFilter() {
            if (this.filterChangePromise) {
                clearTimeout(this.filterChangePromise)
            }
            this.filterChangePromise = setTimeout(() => {
                this.filter.page = 1
                this.getDevices()
            }, 500);

        },
        movePage(page) {
            this.filter.page = page
            this.getDevices()
        },
        getDeviceGreen(device) {
            //생성일 3일이내거나 최종로그인이 3일이내일경우 return true

            let now = new Date()
            let createDate = new Date(device.createDate)
            let lastLoginDate = new Date(device.lastLoginDate)

            let diffCreate = now.getTime() - createDate.getTime()
            let diffLogin = now.getTime() - lastLoginDate.getTime()

            if (diffCreate < 1000 * 60 * 60 * 24 * 3 || diffLogin < 1000 * 60 * 60 * 24 * 3) {
                return true
            } else {
                return false
            }

        },
        remove(device) {
            console.log(device.deviceId)
            if (device.deviceId != prompt(`정말 삭제하시겠습니까? 삭제하려면 디바이스이름을 입력해주세요`)) {
                return
            }
            this.$axios.post("/root/device/remove", device)
                .then(result => {
                    if (result.data.res) {
                        window.alert("삭제되었습니다.")
                        this.getDevices()
                    }
                })
        },
        modify(device) {
            this.$axios.post("/root/device/modify", device)
                .then(result => {
                    if (result.data.res) {
                        window.alert("수정되었습니다.")
                        this.getDevices()
                    }
                })
        },
        getDevices() {
            this.$axios.post("/root/device/list", this.filter)
                .then(result => {
                    if (result.data.res) {
                        this.devices = result.data.list
                        this.nav = result.data.nav
                    }
                })
        },
        getStores() {
            this.$axios.post("/root/store/list/all")
                .then(result => {
                    if (result.data.res) {
                        this.stores = result.data.list
                    }
                })
        }
    }
}
</script>

<style scoped lang="less">
.root {
    width: 100%;
}

.text-green {
    color: green;
    font-weight: 700;
}
</style>