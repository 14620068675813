import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: null,
    group: null,
    storeInfo: null,
    loginCallCount: 0,
    loginRedirect: "/",
    landingTop: false,
    token: null,
    orderStoreId: null,
    orderTableId: null,
    orderTableOrderId: null
  },
  getters: {
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    setGroup(state, group) {
      state.group = group
    },
    logout(state) {
      state.userInfo = null
      state.group = null
    },
    setToken(state, token) {
      state.token = token
    },
    setStoreInfo(state, storeInfo) {
      state.storeInfo = storeInfo
    },
    setOrderStoreId(state, orderStoreId) {
      state.orderStoreId = orderStoreId
    },
    setOrderTableId(state, orderTableId) {
      state.orderTableId = orderTableId
    },
    setOrderTableOrderId(state, orderTableOrderId) {
      state.orderTableOrderId = orderTableOrderId
    },
  },
  actions: {
  },
  modules: {
  }
})
