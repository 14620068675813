<template>
  <div ref="root">


  </div>
</template>

<script>
import numeral from "numeral";
import Vue from "vue";
import "animate.css";
import AOS from "aos";
import "aos/dist/aos.css";


import AppFooter from "@/components/AppFooter"
import AppHeader from "@/components/AppHeader"

export default {
  components: {
    AppFooter,
    AppHeader
  },
  layout: "landing",
  data() {
    return {

    };
  },

  mounted() {
    this.$router.replace("/pos")
  },

};
</script>
<style lang="less" scoped>
.divider {
  height: 15px;
  background: #eee;
}

.inner-container {
  height: 100%;
  padding: 20px;
}

.fullpage-section {
  padding-top: 60px;
  padding-bottom: 60px;
  color: #888;

  .page {
    height: 100vh;
    position: relative;
  }

  .header {
    font-size: 55px;
    line-height: 60px;
    position: relative;
    font-weight: bold;

    b {
      color: @primary-color;
    }

    z-index: 2;
  }

  .inner-container {
    height: 100%;
  }

  .sub-header {
    margin-top: 24px;
    font-size: 26px;
    position: relative;
    z-index: 2;
  }

  .bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    // background-image: url(/img/top.jpg);
    background-size: cover;
    background-position: center center;

    z-index: 1;
    background: #fefefe;
  }
}

.description-container {
  .description {
    width: 500px;
    color: black;

    .description-header {
      font-size: 30px;

      font-weight: bold;
      margin-bottom: 20px;
    }

    .description-body {
      font-size: 20px;
      text-align: left;
      color: #888;
    }
  }

  .image {
    width: 100%;
    height: 100%;
  }
}

.form {
  text-align: center;
}

@media screen and (max-width: 680px) {
  .fab-btn {
    bottom: 80px;

    &.contact {
      bottom: 130px;
    }
  }

  .main-section {
    padding: 20px;
  }

  .fullpage-section {
    .header {
      font-size: 40px;
      line-height: 45px;
    }

    .sub-header {
      font-size: 20px;
      font-weight: bold;
    }

    .description {
      font-size: 16px;
    }
  }

  .navigator-list {
    right: 5px;
  }

  .store {
    .name {
      font-size: 16px !important;
    }

    .rating {
      b {
        font-size: 16px !important;
      }

      // margin-right: 5px;
    }
  }

  .mockup-section {
    .header {
      margin-top: 60px;
      text-align: center;
    }

    .mockup {
      text-align: center;
      padding: 20px;

      img {
        // width: 100%;
        width: 60%;
        // height: 300px;
      }
    }
  }

  .vs-table {
    thead {
      th {
        font-size: 20px;
      }
    }

    tbody {
      tr {
        td {
          font-size: 14px;
        }
      }
    }
  }
}

b {
  color: @primary-color;
}

.system-image {
  width: 100%;
}

.form {
  width: 500px;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.fab-fade-enter-active,
.fab-fade-leave-active {
  transition: all 0.2s;
}

.fab-fade-enter,
.fab-fade-leave-to {
  transform: scale(0%);
}

.fab-fade2-enter-active,
.fab-fade2-leave-active {
  transition: all 0.2s;
  transition-delay: 0.1s;
}

.fab-fade2-enter,
.fab-fade2-leave-to {
  transform: scale(0%);
}

.mobile-mockup {
  border-radius: 20px;
  width: 500px;
  max-width: 100%;
}

.mockup-pc {
  width: 600px;
  max-width: 100%;
}
</style>
