<template>
  <div class="root">
    <div class="section-box">
      <v-layout align-center>
        <div class="header">매장목록</div>
        <v-spacer></v-spacer>
        <v-btn class="mr-2" @click="openCloneDialog" outlined color="primary">매장 복사</v-btn>
        <v-btn @click="openDialog" outlined color="primary">추가</v-btn>
      </v-layout>
    </div>

    <div class="store-list">
      <div class="store-item" v-for="(store, key) in list" :key="key" v-ripple @click="modifyItem(store)">
        <div class="title">{{ store.title }}</div>
        <div class="description">{{ store.registNumber }}</div>
      </div>
    </div>

    <v-dialog width="500px" v-model="cloneDialog.visible">
      <v-card class="pa-2">
        <div class="input-header">복사할 매장 선택</div>
        <v-select class="input" v-model="cloneDialog.storeId" solo flat hide-details :items="list" item-value="id"
          item-text="title"></v-select>
        <div class="text-center mt-4">
          <v-btn color="primary" depressed @click="clone">추가</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      cloneDialog: {
        visible: false,
        storeId: null,
      },
      columns: [
        {
          header: "매장명",
          name: "title",
          minWidth: 140,
          align: "center",
        },
        {
          header: "사업자등록번호",
          name: "registNumber",
          minWidth: 140,
          align: "center",
        },
        {
          header: "오픈일",
          name: "openDate",
          width: 100,
        },
        {
          header: "주소",
          name: "address",
          align: "left",
          minWidth: 120,
        },
      ],
    };
  },
  mounted() {
    this.getStoreList();
  },
  methods: {
    clone() {
      if (this.cloneDialog.storeId == null) {
        alert("복사할 매장을 선택해주세요.");
        return;
      }
      this.$axios.post("/root/store/clone", {
        id: this.cloneDialog.storeId,
      }).then((result) => {
        if (result.data.res) {
          this.getStoreList();
          this.cloneDialog.visible = false;
        }
      });

    },
    openCloneDialog() {
      this.cloneDialog.visible = true;
    },
    clickGrid(event) {
      if (event.rowKey >= 0) {
        this.modifyItem(this.list[event.rowKey]);
      }
    },
    moveManage(item) {
      this.$router.push(`/root/store/staff/${item.id}`);
    },
    async getStoreList() {
      var { data } = await this.$axios.post("/root/store/list");

      this.list = data.list;
    },
    removeItem(item) {
      if (!confirm("정말 삭제하시겠습니까?")) return;
      this.$axios
        .post("/root/store/remove", {
          id: item.id,
        })
        .then((result) => {
          if (result.data.res) {
            this.getStoreList();
          }
        });
    },

    openDialog() {
      this.$router.push("/root/store/create");
    },
    modifyItem(item) {
      this.$router.push("/root/store/" + item.id);
    },
  },
};
</script>
<style lang="less" scoped>
.store-list {
  .store-item {
    background: white;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 0 6px #ddd;
    cursor: pointer;

    .title {
      font-size: 20px;
      font-weight: bold;
    }

    .description {
      color: #999;
      font-size: 12px;
    }
  }
}
</style>