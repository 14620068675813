<template>
    <div>

    </div>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    mounted() {
        this.$store.commit("setOrderTableOrderId", this.$route.params.tableOrderId)
        this.$router.replace("/order")
    }
}
</script>

<style scoped lang="less"></style>