import Vue from 'vue';
import _ from "lodash"
import moment from "moment"


Vue.mixin({
    data() {
        return {
            now: new Date()
        }
    },

    computed: {
        getVisibleCategorys() {
            console.log("getVisibleCategorys")
            return _.chain(this.categoryList)
                .filter(category => {

                    //아무것도 설정되있지않으면 무조건 보여주기
                    if (!category.visibleStartTime && !category.visibleEndTime && !category.hiddenStartTime && !category.hiddenEndTime) {
                        return true
                    }
                    //노출시간이 설정되어있으면 노출시간에만 보여주기
                    else if (category.visibleStartTime && category.visibleEndTime) {

                        let visibleStartTime = moment(category.visibleStartTime, "HH:mm:ss").toDate()
                        let visibleEndTime = moment(category.visibleEndTime, "HH:mm:ss").toDate()
                        //오늘의 요일 가져오기
                        let day = moment().day()
                        if (this.now >= visibleStartTime && this.now <= visibleEndTime) {

                            //요일도 맞는지 체크
                            if (day == 0 && category.visibleSun) {
                                return true
                            }
                            else if (day == 1 && category.visibleMon) {
                                return true
                            }
                            else if (day == 2 && category.visibleTue) {
                                return true
                            }
                            else if (day == 3 && category.visibleWed) {
                                return true
                            }
                            else if (day == 4 && category.visibleThu) {
                                return true
                            }
                            else if (day == 5 && category.visibleFri) {
                                return true
                            }
                            else if (day == 6 && category.visibleSat) {
                                return true
                            }
                            else {
                                return false
                            }


                        }
                        else {
                            return false
                        }
                    }
                    //숨김시간이 설정되어있으면 숨김시간에는 보여주지않기
                    else if (category.hiddenStartTime && category.hiddenEndTime) {

                        let hiddenStartTime = moment(category.hiddenStartTime, "HH:mm:ss").toDate()
                        let hiddenEndTime = moment(category.hiddenEndTime, "HH:mm:ss").toDate()
                        if (this.now >= hiddenStartTime && this.now <= hiddenEndTime) {
                            //요일도 체크

                            let day = moment().day()
                            if (day == 0 && category.hiddenSun) {
                                return false
                            }
                            else if (day == 1 && category.hiddenMon) {
                                return false
                            }
                            else if (day == 2 && category.hiddenTue) {
                                return false
                            }
                            else if (day == 3 && category.hiddenWed) {
                                return false
                            }
                            else if (day == 4 && category.hiddenThu) {
                                return false
                            }
                            else if (day == 5 && category.hiddenFri) {
                                return false
                            }
                            else if (day == 6 && category.hiddenSat) {
                                return false
                            }
                            else {
                                return true
                            }

                        }
                        else {
                            return true
                        }
                    }
                    else {
                        return true
                    }
                })
                .value()


        }
    },
})