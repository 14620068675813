<template>
    <div v-if="store">
        <div class="section-box">
            <table class="table">
                <tr>
                    <th>매장명</th>
                    <td>
                        <hh-input dense v-model="store.title"></hh-input>
                    </td>
                </tr>
                <tr>
                    <th>사업자등록번호</th>
                    <td>
                        <hh-input dense v-model="store.registNumber"></hh-input>
                    </td>
                </tr>
                <tr>
                    <th>대표자</th>
                    <td>
                        <hh-input dense v-model="store.ownerName"></hh-input>
                    </td>
                </tr>
                <tr>
                    <th>주소</th>
                    <td>
                        <div>
                            <hh-input class="input" dense v-model="store.address"></hh-input>
                        </div>
                        <div>
                            <hh-input class="input mt-2" dense v-model="store.addressDetail"></hh-input>
                        </div>


                    </td>
                </tr>

            </table>
            <div class="text-center mt-2">
                <v-btn color="primary" text @click="modify">수정</v-btn>
            </div>

        </div>
        <div class="section-box mt-2">
            <div>키오스크 설정</div>
            <v-select placeholder="커버이미지/영상 타입" solo flat hide-details class="input" v-model="store.kioskCoverType"
                :items="kioskCoverType"></v-select>


            <div v-if="store.kioskCoverType == 1">
                <div class="input-header">
                    첫화면 이미지파일
                </div>

                <div class="image-list">
                    <div class="image-item">
                        <image-box accept="image/*" :multiple="true" @change="changeCovers"></image-box>
                    </div>
                    <draggable v-model="store.coverImages" @change="reorderImages">
                        <div class="image-item" v-for="(img, key) in store.coverImages" v-if="!img.deleted">
                            <v-icon class="close-btn" @click="removeImage(store.coverImages, img)">mdi-close</v-icon>
                            <v-img class="image" :key="key" :src="img.imageMUrl || img.data"></v-img>
                        </div>
                    </draggable>
                </div>
            </div>
            <div class="video-section" v-else-if="store.kioskCoverType == 2">
                <div class="input-header">
                    메인화면 비디오파일
                </div>
                <input-file @change="changeKioskCoverVideo" accept="video/*">
                    <v-btn color="primary" outlined>영상선택</v-btn>
                </input-file>
                <div v-if="kioskCoverVideo || store.kioskCoverVideo" class="video-container">
                    <video class="video" :src="getKioskCoverVideo()" autoplay muted loop></video>
                    <div>
                        <v-btn text color="error">영상 삭제</v-btn>
                    </div>

                </div>
            </div>



            <div class="text-center mt-2">
                <v-btn color="primary" text @click="modify">수정</v-btn>
            </div>

        </div>

    </div>
</template>

<script>
import asyncForEach from "async-await-foreach"
import draggable from "vuedraggable";
import InputFile from "@/components/InputFile";
import kioskCoverType from "@/data/kioskCoverType"
export default {
    components: {
        draggable,
        InputFile
    },
    data() {
        return {
            kioskCoverType,
            store: null,
            kioskCoverVideo: null

        }
    },
    mounted() {
        this.getStore()
    },
    methods: {
        getKioskCoverVideo() {
            if (this.kioskCoverVideo) {
                return URL.createObjectURL(this.kioskCoverVideo)
            }
            else if (this.store.kioskCoverVideo) {
                return this.store.kioskCoverVideo.imageUrl
            }
        },
        changeKioskCoverVideo(file) {
            console.log(file)
            this.kioskCoverVideo = file
        },
        reorderImages() {
            _.each(this.store.coverImages, (item, key) => {
                item.order = key;
            });
        },
        getPrefill(image) {
            if (image) {
                return image.imageUrl
            } else {
                return null
            }
        },
        async modify() {
            // if (this.mainImage) {
            //     var formData = new FormData()
            //     formData.append("file", this.mainImage)
            //     var { data } = await this.$axios.post("/file/upload", formData)
            //     this.store.imageId = data.file.id

            // }

            if (this.kioskCoverVideo) {
                var formData = new FormData();
                formData.append("file", this.kioskCoverVideo);
                var { data } = await this.$axios.post("/file/upload/video", formData);
                this.store.kioskCoverVideoId = data.file.id;
            }
            await asyncForEach(this.store.coverImages, async (image, key) => {
                if (!image.id) {
                    var formData = new FormData();
                    formData.append("file", image);
                    var { data } = await this.$axios.post("/file/upload", formData);
                    this.store.coverImages[key] = data.file;
                }
            });
            this.$axios.post("/pos/store/modify", this.store)
                .then(result => {
                    if (result.data.res) {
                        window.alert("정보가 수정되었습니다")
                    }
                })
        },
        getStore() {
            this.$axios.post("/pos/store/info")
                .then(result => {
                    if (result.data.res) {
                        this.store = result.data.store
                    }
                })

        },

        changeCovers(files) {
            this.store.coverImages.push(files)
            // this.item.coverImages = files;
        },
        removeImage(list, item) {
            if (item.id) {
                console.log(item);
                item.deleted = true;
            } else {
                list.splice(list.indexOf(item), 1);
            }
        },

    }
}
</script>

<style scoped lang="less">
.section-box {
    width: 500px;
    max-width: 100%;
    margin: 20px auto;
}

.imagebox-container {
    width: 100px;
    height: 100px;
    display: inline-block;
}

.image-list {
    display: inline-block;
    ;

    .image-item {
        display: inline-block;
        width: 100px;
        height: 100px;
        vertical-align: middle;
        margin-right: 10px;
        margin-bottom: 10px;
        position: relative;

        .close-btn {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 10;
        }

        .image {
            width: 100px;
            height: 100px;
            border-radius: 5px;
        }
    }
}

.video-section {
    min-height: 400px;

    .video-container {

        width: 100%;
        text-align: center;

        .video {
            max-width: 100%;
            height: 400px;
            aspect-ratio: 9/16;
        }
    }
}
</style>