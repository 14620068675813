<template>
  <div>
    <div class="footer hidden-lg-and-up">
      <!-- <v-layout wrap class="first-section">
        <v-flex class="text-xs-center">
          <a
            class="item"
            target="_blank"
            href="https://innobank.co.kr/terms/service"
            >이용약관</a
          >
          <div class="dot">·</div>
          <a
            class="item"
            target="_blank"
            href="https://innobank.co.kr/terms/privacy"
            >개인정보처리방침</a
          >
        </v-flex>
      </v-layout> -->
      <!-- <v-layout wrap class="copyright-section">
          <v-flex class="btn-container text-xs-center">
          <a href="http://www.kipo.go.kr/kpo/user.tdf?a=user.main.MainApp" target="_blank">
            <img class="link-btn" src="/img/logo_01.png" alt="">
          </a>
          <a href="https://www.patent.go.kr/portal/Main.do" target="_blank">
            <img class="link-btn" src="/img/logo_02.png" alt="">
          </a>
          <a href="http://www.kipris.or.kr/khome/main.jsp" target="_blank">
            <img class="link-btn" src="/img/logo-03.png" alt="">
          </a>
        </v-flex>
      </v-layout> -->
      <v-layout wrap class="second-section pb-4">
        <v-flex class="link-list text-xs-center">
          <div>
            <div class="item">주식회사 훈훈소프트</div>
            <div class="dot">|</div>
            <div class="item">대표 김태훈</div>
          </div>
          <div>
            <div class="item">사업자등록번호 551-88-00694</div>
            <div class="dot">|</div>
            <a
              class="item link"
              target="_blank"
              href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=5518800694"
              >통신판매업 2020-서울송파-2388</a
            >
          </div>
          <div>
            <div class="item">고객센터 070-4333-1230</div>
            <div class="dot">|</div>
            <div class="item">
              서울특별시 송파구 백제고분로 276, 공익빌딩 502호
            </div>
          </div>
          <div></div>

          <div class="text-xs-center mt-4">
            <div class="title">© 주식회사 훈훈소프트</div>
          </div>
        </v-flex>
      </v-layout>
    </div>

    <div class="footer hidden-md-and-down">
      <!-- <v-layout wrap class="first-section">
        <v-flex>
          <a
            class="item"
            target="_blank"
            href="https://innobank.co.kr/terms/service"
            >이용약관</a
          >
          <div class="dot">·</div>
          <a
            class="item"
            target="_blank"
            href="https://innobank.co.kr/terms/privacy"
            >개인정보처리방침</a
          >
        </v-flex>
        <v-spacer></v-spacer>
      </v-layout> -->
      <v-layout wrap class="second-section">
        <v-flex class="link-list">
          <div>
            <div class="item">주식회사 훈훈소프트</div>
            <div class="dot">|</div>
            <div class="item">대표 김태훈</div>
            <div class="dot">|</div>
            <div class="item">고객센터 070-4333-1230</div>
            <div class="dot">|</div>
            <div class="item">사업자등록번호 551-88-00694</div>
          </div>
          <div>
            <a
              class="item link"
              target="_blank"
              href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=5518800694"
              >통신판매업 2020-서울송파-2388</a
            >
            <div class="dot">|</div>
            <div class="item">
              서울특별시 송파구 백제고분로 276, 공익빌딩 502호
            </div>
          </div>
          <div></div>
        </v-flex>
      </v-layout>
      <v-layout wrap class="copyright-section">
        <v-flex
          class="
            mt-2
            btn-container
            text-xs-center text-sm-center text-md-center text-lg-right
          "
        >
          <!-- <a
            href="http://www.kipo.go.kr/kpo/user.tdf?a=user.main.MainApp"
            target="_blank"
          >
            <img class="link-btn" src="/img/logo_01.png" alt="" />
          </a>
          <a href="https://www.patent.go.kr/portal/Main.do" target="_blank">
            <img class="link-btn" src="/img/logo_02.png" alt="" />
          </a>
          <a href="http://www.kipris.or.kr/khome/main.jsp" target="_blank">
            <img class="link-btn" src="/img/logo-03.png" alt="" />
          </a> -->
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="less">
.footer {
  display: block;
  border-top: 1px solid #d6d6d6;
  // padding: 24px 64px 0;
  text-align: left;
  background: #1c1c1c;
  color: white;
  .first-section {
    padding: 25px 34px;
    background: #343434;
    .btn {
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }
  }
  .second-section {
    padding: 25px 34px 0;
  }
  .dot,
  .item {
    margin-top: 3px;
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    margin-left: 2px;
    margin-right: 2px;
    color: white;
  }

  .copyright-section {
    padding: 50px 34px 25px;
    .text {
      font-size: 14px;
    }
    .btn-container {
      text-align: right;
      .link-btn {
        margin-left: 15px;
        margin-right: 15px;
        height: 30px;
      }
    }
  }
}
a {
  color: white !important;
  text-decoration: none;
}
@media screen and (max-width: 680px) {
  .item {
    font-size: 12px !important;
  }
  .copyright-section {
    .text {
      font-size: 12px !important;
    }
  }
  .title {
    font-size: 12px !important;
  }
}
</style>
