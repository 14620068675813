<template>
    <div class="root">
        <div class="section-box">
            <div class="section-header">
                메뉴별 매출
            </div>
            <v-layout align-center class="mt-2">
                <div>
                    <date-picker v-model="filter.startDate"></date-picker>
                </div>
                <div>
                    <date-picker v-model="filter.endDate"></date-picker>
                </div>

                <v-spacer></v-spacer>
                <v-btn @click="search()" depressed color="primary">조회</v-btn>
            </v-layout>
        </div>
        <div class="section-box mt-2" v-if="incomeChart && incomesByMenu.length > 0">
            <div class="chart-container">
                <pie-chart :data="incomeChart" :colors="colors"></pie-chart>
            </div>
        </div>
        <div class="section-box mt-2">
            <div class="header">총 매뉴갯수</div>
            <div class="value">
                {{ totalMenuCount }}개
            </div>
            <div class="header">매뉴당 평균매출</div>
            <div class="value">
                {{ totalPrice / totalMenuCount | number("0") }}원
            </div>
        </div>
        <div class="list" v-if="total">
            <div class="item" v-for="(item, key) in sortByPrice()" :key="key">
                <v-layout align-center>
                    <div class="rate">
                        {{ ((item.price * item.count) / total.price) * 100 | number("0.0") }}%
                    </div>
                    <v-flex>{{ item.title }}</v-flex>
                    <div class="text-right">
                        <div class="price">
                            {{ item.price * item.count | number }}원
                        </div>
                        <div class="count">
                            {{ item.count }}건
                        </div>

                    </div>
                </v-layout>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import _ from "lodash"
export default {
    data() {
        return {
            filter: {
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
            incomesByMenu: [],
            total: null,
            incomeChart: null,
            colors: []
        }
    },
    mounted() {
        this.search()
    },
    computed: {
        totalMenuCount() {
            return _.sumBy(this.incomesByMenu, (item) => {
                return item.count
            })
        },
        totalPrice() {
            return _.sumBy(this.incomesByMenu, (item) => {
                return item.price * item.count
            })
        }
    },
    methods: {
        sortByPrice() {
            return _.sortBy(this.incomesByMenu, (item) => {
                return item.price * item.count
            }).reverse()
        },
        search() {
            this.$axios.post("/pos/incomes/menu", this.filter).then(res => {

                this.incomesByMenu = res.data.incomesByMenu

                this.total = res.data.total
                this.colors = []
                this.incomeChart = _.map(this.sortByPrice(), (item, key) => {
                    // console.log(key, this.incomesByMenu.length, key / this.incomesByMenu.length)
                    var alpha = 255 - Math.ceil((key + 1) / this.incomesByMenu.length * (250))

                    console.log(item.title, item.price, alpha)
                    var alpha16 = alpha.toString(16)
                    if (alpha16.length == 1) {
                        alpha16 = "0" + alpha16
                    }
                    this.colors.push("#ffa600" + alpha16);
                    return {
                        name: item.title,
                        y: item.price * item.count
                    }
                })

                // {
                //     labels: _.map(this.incomesByMenu, (item) => {
                //         return item.title
                //     }),
                //     datas: [
                //         {
                //             name: "매출",
                //             data: _.map(this.incomesByMenu, (item) => {
                //                 return item.price;
                //             }),
                //         },
                //     ],
                // };

            })
        }
    }
}
</script>

<style scoped lang="less">
.list {
    .item {
        .rate {
            font-size: 20px;
            font-weight: 700;
            margin-right: 20px;
        }

        .price {
            font-size: 20px;
            font-weight: 700;
        }
    }
}

.header {
    font-size: 20px;
    text-align: center;
}

.value {
    font-size: 25px;
    font-weight: 700;
    text-align: center;

}
</style>