<template>
  <highcharts :options="chartOptions"></highcharts>
</template>
<script>
import moment from "moment";
import numeral from "numeral";
import _ from "underscore";
import Highcharts from "highcharts";
export default {
  props: ["data", "title"],
  data() {
    return {
      results: [],

      chartOptions: {
        chart: {
          type: "areaspline",

          height: 300,
          spacing: 0,
          margin: [0, 0, 30, 0],
          // spacingLeft: 0,
          // spacingRight: 0,
        },

        credits: {
          enabled: false,
        },
        title: {
          text: "",
        },
        xAxis: {
          title: {
            text: null,
          },
          minPadding: 0,
          maxPadding: 0,
        },

        series: [],
        tooltip: {
          formatter: function () {
            var unit = "원";
            var format = "0,000";
            if (this.series.name.indexOf("률") > -1) {
              unit = "%";
              format = "0.00";
            }
            return `${this.x} ${this.series.name} : <b>${numeral(this.y).format(
              format
            )}${unit}</b>`;
          },
        },

        plotOptions: {
          line: {
            color: "#000000",
          },
          areaspline: {
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1,
              },
              stops: [
                [0, "#ffa600"],
                [1, "#ffa60000"],
              ],
            },
            marker: {
              radius: 2,
            },
            lineWidth: 1,
            states: {
              hover: {
                lineWidth: 1,
              },
            },
            threshold: null,
          },
        },
        legend: {
          enabled: false,
        },
        colors: ["#ffa600"],
        xAxis: {
          visible: true,
          categories: [],
        },

        yAxis: [
          {
            visible: false,
            title: {
              text: null,
            },
            labels: {
              enabled: false,
            },
          },
          {
            // visible: false,
            title: {
              text: null,
            },
            labels: {
              enabled: false,
            },
            align: "right",
            opposite: true,
            gridLineWidth: 0,
            plotLines: [
              {
                value: 0,
                color: "black",

                width: 2,
                zIndex: 100,
                label: {
                  text: "손익분기",
                },
              },
            ],
          },
        ],
      },
    };
  },
  watch: {
    data() {
      this.initChart();
    },
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      this.chartOptions.title.text = this.title
      this.chartOptions.xAxis.categories = [];
      var tmpData = _.clone(this.data);
      this.chartOptions.series = tmpData.datas;
      this.chartOptions.xAxis.categories = tmpData.labels;
      if (this.chartOptions.series.length >= 1) {
        this.chartOptions.series[0].type = "areaspline";
        this.chartOptions.series[0].pointPlacement = "on";

        this.chartOptions.yAxis[0].title.text = tmpData.datas[0].name;
      }
      if (this.chartOptions.series.length >= 2) {
        this.chartOptions.series[1].type = "line";
        this.chartOptions.series[1].pointPlacement = "on";
        this.chartOptions.yAxis[1].title.text = tmpData.datas[1].name;
        this.chartOptions.series[1].yAxis = 1;


      }

    },
  },
};
</script>
<style scoped lang="less">
.container {
  width: 100%;
  max-width: 100%;
}

.empty-top {
  height: 15px;
}

.empty-bottom {
  height: 90px;
}
</style>
