<template>
    <div class="root">
        <div class="section-box">
            <div class="section-header">
                일자별 매출
            </div>
            <v-layout>
                <div>
                    <date-picker class="input" v-model="filter.date"></date-picker>
                </div>

                <v-spacer></v-spacer>
                <v-btn @click="search()" depressed color="primary">조회</v-btn>
            </v-layout>
        </div>
        <div class="section-box mt-2" v-if="orderMethodSum.length > 0">
            <div class="value-header">총매출액</div>
            <div class="value">{{ totalPrice | number }}원</div>

            <div v-for="(item, key) in orderMethodSum" :key="key">
                <div class="value-header">{{ item.orderMethod | orderMethod }}</div>
                <div class="value">{{ item.totalPrice | number }}원</div>
            </div>
        </div>

        <div class="list" v-if="list.length > 0">
            <div class="list-header">매출내역</div>
            <div class="item" v-for="(item, key) in list" :key="key">
                <v-layout align-center>
                    <div class="order-number">
                        {{ item.orderNumber }}
                    </div>
                    <v-flex>
                        <div>{{ item.orderMethod | orderMethod }}</div>
                        <div class="card">
                            <div class="card-type">
                                {{ item.outAccepterName }}
                            </div>
                            <div>
                                {{ item.outCardNo | cardNo }}
                            </div>

                        </div>
                        <div class="approve-no" v-if="item.orderMethod == 100">
                            승인번호 : {{ item.approveNo }}
                        </div>
                    </v-flex>
                    <div>
                        <div class="amount">
                            {{ item.totalPrice | number }}원
                        </div>
                        <div class="time">
                            {{ item.createDate | date("HH:mm") }}
                        </div>

                    </div>


                </v-layout>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
export default {
    data() {
        return {
            filter: {
                date: moment().format("YYYY-MM-DD")
            },
            list: [],
            orderMethodSum: [],
            orderTypeSum: []
        }
    },
    mounted() {
        this.search()
    },
    computed: {
        totalPrice() {
            return this.orderMethodSum.reduce((acc, cur) => acc + cur.totalPrice, 0)
        }
    },
    methods: {
        search() {
            this.$axios.post("/incomes", { startDate: this.filter.date, endDate: this.filter.date })
                .then(result => {
                    if (result.data.res) {
                        console.log(result.data)
                        this.list = result.data.list
                        this.orderMethodSum = result.data.orderMethodSum
                        this.orderTypeSum = result.data.orderTypeSum
                    }
                })
        }
    }
}
</script>

<style scoped lang="less">
.root {
    padding-bottom: 20px;
    width: 600px;
}

.section-box {


    .value-header {
        font-size: 20px;
        font-weight: 500;
        text-align: center;
    }

    .value {
        font-size: 25px;
        font-weight: 700;
        text-align: center;
    }
}

.list {
    .list-header {
        font-size: 20px;
        font-weight: 700;
        margin-left: 5px;
        margin-top: 10px;
    }

    .item {
        .order-number {
            margin-right: 10px;
            font-size: 14px;
            font-weight: 700;
        }

        .card-type {
            font-size: 18px;
            font-weight: 700;
        }

        .amount {
            font-size: 18px;
            font-weight: 700;
        }

        .time {
            font-size: 14px;
            font-weight: 700;
            text-align: center;
            color: #9e9e9e;

        }
    }
}
</style>