import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: require("../views/index.vue").default,
  },
  {
    path: '/login',
    component: require("../views/login.vue").default,
  },
  {
    path: '/order',
    component: require("../views/order/index.vue").default,

  },
  {
    path: '/order/:storeId/:tableId',
    component: require("../views/order/_storeId/_tableId.vue").default,

  },
  {
    path: '/order/:tableOrderId',
    component: require("../views/order/_tableOrderId.vue").default,

  },
  {
    path: '/root',
    component: require("../views/root.vue").default,
    children: [

      // 대시보드
      { path: "dashboard", component: require("../views/root/dashboard.vue").default },
      // 매장
      { path: "store", component: require("../views/root/store/index.vue").default },
      { path: "store/:id", component: require("../views/root/store/_id.vue").default },
      // 메뉴관리
      { path: "menu", component: require("../views/root/menu/index.vue").default },
      { path: "menu/:storeId", component: require("../views/root/menu/_storeId.vue").default },
      // 디바이스관리
      { path: "device", component: require("../views/root/device/index.vue").default },
      //테이블오더
      { path: "tableorder", component: require("../views/root/tableorder/index.vue").default },
      { path: "tableorder/:id", component: require("../views/root/tableorder/_id.vue").default },
      { path: "tablemanage", component: require("../views/root/table/index.vue").default },
      { path: "tablemanage/:id", component: require("../views/root/table/_id.vue").default },
    ]
  },
  {
    path: "/pos/main",
    component: require("../views/pos/main.vue").default,
    children: [
      { path: "/", component: require("../views/pos/main/index.vue").default },

      //매장정보
      { path: "store/dashboard", component: require("../views/pos/main/store/dashboard.vue").default },
      { path: "store/info", component: require("../views/pos/main/store/info.vue").default },

      //메뉴관리
      { path: "menu/category", component: require("../views/pos/main/menu/category.vue").default },
      { path: "menu/item", component: require("../views/pos/main/menu/item.vue").default },
      { path: "menu/option", component: require("../views/pos/main/menu/option.vue").default },

      { path: "incomes/daily", component: require("../views/pos/main/incomes/daily.vue").default },
      { path: "incomes/time", component: require("../views/pos/main/incomes/time.vue").default },
      { path: "incomes/menu", component: require("../views/pos/main/incomes/menu.vue").default },
      { path: "incomes/method", component: require("../views/pos/main/incomes/method.vue").default },
      { path: "incomes/monthly", component: require("../views/pos/main/incomes/monthly.vue").default },
    ]
  },
  {
    path: '/pos',
    component: require("../views/pos/index.vue").default,
  },



]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
