<template>
  <highcharts :options="chartOptions"></highcharts>
</template>
<script>
import moment from "moment";
import numeral from "numeral";
import _ from "underscore";
import Highcharts from "highcharts";
export default {
  props: ["data"],
  data() {
    return {
      results: [],

      chartOptions: {
        chart: {
          type: "column",

          height: 300,
          // spacing: 0,
          // margin: [20, 20, 30, 20],

          // spacingTop: 20,    // 상단 여백
          // spacingRight: 20,  // 우측 여백
          // spacingBottom: 20, // 하단 여백
          // spacingLeft: 20,   // 좌측 여백
        },

        credits: {
          enabled: false,
        },
        title: {
          text: "",
        },
        xAxis: {
          title: {
            text: null,
          },
          // minPadding: 50,
          // maxPadding: 100,
        },

        series: [],
        tooltip: {
          formatter: function () {
            var unit = "원";
            var format = "0,000";
            if (this.series.name.indexOf("률") > -1) {
              unit = "%";
              format = "0.00";
            }
            return `${this.x} ${this.series.name} : <b>${numeral(this.y).format(
              format
            )}${unit}</b>`;
          },
        },

        legend: {
          enabled: false,
        },
        colors: ["#f9b724"],
        xAxis: {
          visible: true,
          categories: [],
        },

        yAxis: [
          {
            visible: false,
            title: {
              text: null,
            },
            labels: {
              enabled: false,
            },
          },
          {
            // visible: false,
            title: {
              text: null,
            },
            labels: {
              enabled: false,
            },
            align: "right",
            opposite: true,
            gridLineWidth: 0,

          },
        ],
      },
    };
  },
  watch: {
    data() {
      this.initChart();
    },
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      // this.chartOptions.series[0].data = [];
      this.chartOptions.xAxis.categories = [];
      var tmpData = _.clone(this.data);
      this.chartOptions.series = tmpData.datas;
      this.chartOptions.xAxis.categories = tmpData.labels;
      if (this.chartOptions.series.length >= 1) {
        this.chartOptions.series[0].type = "column";
        this.chartOptions.series[0].pointPlacement = "on";

        this.chartOptions.yAxis[0].title.text = tmpData.datas[0].name;
      }
      if (this.chartOptions.series.length >= 2) {
        this.chartOptions.series[1].type = "line";
        this.chartOptions.series[1].pointPlacement = "on";
        this.chartOptions.yAxis[1].title.text = tmpData.datas[1].name;
        this.chartOptions.series[1].yAxis = 1;

        // this.chartOptions.yAxis[1].min = _.min(
        //   this.chartOptions.series[1].data
        // );
        // this.chartOptions.yAxis[1].max = _.max(
        //   this.chartOptions.series[1].data
        // );
      }
      // _.each(tmpData, (item) => {
      //   this.chartOptions.series[0].data.push(item.value);
      //   this.chartOptions.xAxis.categories.push(item.date);
      // });
    },
  },
};
</script>
<style scoped lang="less">
.container {
  width: 100%;
  max-width: 100%;
}

.empty-top {
  height: 15px;
}

.empty-bottom {
  height: 90px;
}
</style>
