<template>
    <div class="root">
        <div class="section-box">
            <div class="section-header">
                시간대별 매출
            </div>
            <v-layout align-center class="mt-2">
                <div>
                    <date-picker v-model="filter.startDate"></date-picker>
                </div>
                <div>
                    <date-picker v-model="filter.endDate"></date-picker>
                </div>

                <v-spacer></v-spacer>
                <v-btn @click="search()" depressed color="primary">조회</v-btn>
            </v-layout>
        </div>
        <div class="section-box mt-2" v-if="incomeChart && incomesByHour.length > 0">
            <div class="chart-container">
                <column-chart :data="incomeChart"></column-chart>
            </div>
        </div>
        <div class="section-box mt-2" v-if="total">
            <div class="header">16시 이전매출</div>
            <div class="value">
                {{ amIncome | number }}원({{ (amIncome / total.price) * 100 | number("0.0") }}%)
            </div>
            <div class="header">16시 이후매출</div>
            <div class="value">
                {{ pmIncome | number }}원({{ (pmIncome / total.price) * 100 | number("0.0") }}%)
            </div>
        </div>
        <div class="list" v-if="total">
            <div class="item" v-for="(item, key) in incomesByHour" :key="key">
                <v-layout align-center>
                    <div class="rate">
                        {{ item.price / total.price * 100 | number("0.0") }}%
                    </div>
                    <v-flex>{{ item.hour }}시</v-flex>
                    <div class="text-right">
                        <div class="price">
                            {{ item.price | number }}원
                        </div>
                        <div class="count">
                            {{ item.count }}건
                        </div>

                    </div>
                </v-layout>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import _ from "lodash"
export default {
    data() {
        return {
            filter: {
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
            incomesByHour: [],
            total: null,
            incomeChart: null
        }
    },
    mounted() {
        this.search()
    },
    computed: {
        amIncome() {
            var amIncome = 0
            _.each(this.incomesByHour, item => {
                if (item.hour < 16) {
                    amIncome += item.price
                }
            })
            return amIncome
        },
        pmIncome() {
            var pmIncome = 0
            _.each(this.incomesByHour, item => {
                if (item.hour >= 16) {
                    pmIncome += item.price
                }
            })
            return pmIncome
        },
        totalIncome() {
            var total = 0
            _.each(this.incomesByHour, item => {
                if (item.hour >= 16) {
                    total += item.price
                }
            })
            return total
        }


    },
    methods: {
        search() {
            this.$axios.post("/pos/incomes/hour", this.filter).then(res => {

                this.incomesByHour = res.data.incomesByHour

                this.total = res.data.total
                this.incomeChart = {
                    labels: _.map(this.incomesByHour, (item) => {
                        return item.hour + 'h'
                    }),
                    datas: [
                        {
                            name: "매출",
                            data: _.map(this.incomesByHour, (item) => {
                                return item.price;
                            }),
                        },
                    ],
                };
                console.log(this.incomeChart)

            })
        }
    }
}
</script>

<style scoped lang="less">
.list {
    .item {
        .rate {
            font-size: 20px;
            font-weight: 700;
            margin-right: 20px;
            width: 60px;
            text-align: center;
        }

        .price {
            font-size: 20px;
            font-weight: 700;
        }
    }
}

.header {
    font-size: 20px;
    text-align: center;
    margin-top: 10px;

}

.value {
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px;
}
</style>