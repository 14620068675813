import { render, staticRenderFns } from "./PieChart.vue?vue&type=template&id=297e7ba4&scoped=true"
import script from "./PieChart.vue?vue&type=script&lang=js"
export * from "./PieChart.vue?vue&type=script&lang=js"
import style0 from "./PieChart.vue?vue&type=style&index=0&id=297e7ba4&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "297e7ba4",
  null
  
)

export default component.exports