<template>
    <div>
        <div class="month-selector">
            <v-layout align-center>


                <v-btn icon @click="prevMonth">
                    <v-icon size="30px">mdi-chevron-left</v-icon>
                </v-btn>

                <div class="month">{{ value | monthFormat }}</div>
                <v-btn icon @click="nextMonth">
                    <v-icon size="30px">mdi-chevron-right</v-icon>
                </v-btn>


            </v-layout>
        </div>
        <client-only>
            <table class="calendar">
                <thead class="calendar-header">
                    <tr>
                        <th class="header-item sunday" width="12.5%">일</th>
                        <th class="header-item" width="12.5%">월</th>
                        <th class="header-item" width="12.5%">화</th>
                        <th class="header-item" width="12.5%">수</th>
                        <th class="header-item" width="12.5%">목</th>
                        <th class="header-item" width="12.5%">금</th>
                        <th class="header-item" width="12.5%">토</th>
                    </tr>
                </thead>
                <tbody class="calendar-body">
                    <tr v-for="(row, rowKey) in tableList" :key="rowKey">
                        <td v-for="(calendar, colKey) in row" :key="colKey" class="cursor" @click="clickDate(calendar)">
                            <div class="body-item" :class="{
                                selected: isSelected(calendar),
                            }">
                                <div class="date-label" v-if="!calendar.isBlank" :class="{
                                    today: isToday(calendar.date),
                                    sunday: colKey == 0,
                                    // sunday: isHoliday(calendar.date),

                                }">
                                    {{ calendar.date }}
                                </div>
                                <div v-if="getSchedule(calendar.date).length > 0">
                                    <div v-for="(schedule) in getSchedule(calendar.date)">
                                        <div class="price text-center">
                                            {{ schedule.price | number }}
                                        </div>
                                        <!-- <div class="count text-center">
                                            ({{ schedule.count | number }})
                                        </div> -->



                                    </div>
                                </div>
                                <!-- <div class="date-point-container"
                                    v-if="!calendar.isBlank && getSchedule(calendar.dateKey).length > 0">
                                    <div class="date-point"></div>
                                </div> -->


                                <!-- 등록된 일정 보이게 -->
                                <!-- <div class="schedule-list">
                                    <div class="schedule-item" v-for="(schedule, key) in getSchedule(calendar.dateKey)"
                                        :key="key" @click="clickSchedule(schedule)">
                                        <div class="text">{{ schedule.text }}</div>
                                    </div>
                                </div> -->
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </client-only>

    </div>
</template>
  
<script>
import moment from "moment";
import numeral from "numeral";
import Vue from "vue";
import _ from "underscore";
export default {
    props: ["value", "scheduleList"],
    data() {
        return {
            month: null,
            tableList: [],
            selectedDate: null,
        };
    },
    watch: {
        value() {
            this.changeMonth();
        },
        scheduleList() {
            this.changeMonth();
        },

    },
    mounted() {
        this.changeMonth();
    },
    filters: {
        monthFormat(value) {
            if (moment(value).get("year") == moment().get("year")) {
                return moment(value).format("MM")
            }
            else {
                return moment(value).format("YYYY-MM")
            }
        }
    },
    methods: {

        clickSchedule(schedule) {
            this.$emit("clickSchedule", schedule);
        },
        getSchedule(date) {

            return _.compact(
                _.map(this.scheduleList, (schedule) => {
                    if (schedule.date == date) {
                        return schedule;
                    }
                })
            );
        },
        clickDate(calendar) {
            if (calendar.isBlank) {
                return;
            }
            this.selectedDate = calendar.dateKey;
            this.$emit("clickDate", calendar);
        },
        isSelected(calendar) {
            if (this.selectedDate && this.selectedDate == calendar.dateKey) {
                return true;
            }
            return false;
        },
        isHoliday(date) {
            return false;
        },
        isToday(date) {
            return (
                moment(this.value, "YYYYMMDD").format("YYYYMM") +
                numeral(date).format("00") ==
                moment().format("YYYYMMDD")
            );
        },
        nextMonth() {
            var date = moment(this.value, "YYYYMMDD")
                .add(1, "months")
                .format("YYYYMMDD");
            this.$emit("input", date);
            this.$emit("changeMonth", date);
            Vue.nextTick(() => {
                this.changeMonth();
            });
        },
        prevMonth() {
            var date = moment(this.value, "YYYYMMDD")
                .add(-1, "months")
                .format("YYYYMMDD");
            this.$emit("input", date);
            this.$emit("changeMonth", date);
            Vue.nextTick(() => {
                this.changeMonth();
            });
        },
        changeMonth() {
            var startDay = moment(this.value, "YYYYMMDD").startOf("month").day();
            var endDay = moment(this.value, "YYYYMMDD").endOf("month").day();
            var startDate = moment(this.value, "YYYYMMDD").startOf("month").date();
            var endDate = moment(this.value, "YYYYMMDD").endOf("month").date();
            this.calendarList = [];
            for (var i = 0; i < startDay; i++) {
                this.calendarList.push({
                    isBlank: true,
                    schedules: [],
                });
            }
            for (var i = startDate; i <= endDate; i++) {
                var dateKey = moment(this.value).set("date", i).format("YYYYMMDD");
                this.calendarList.push({
                    isBlank: false,
                    date: i,
                    dateKey: dateKey,
                    dateFormat: moment(dateKey).format("YYYY-MM-DD"),
                    day: moment(this.value).set("date", i).day(),
                    schedules: [],
                });
            }
            for (i = endDay + 1; i <= 6; i++) {
                this.calendarList.push({
                    isBlank: true,
                    schedules: [],
                });
            }
            var row = 0;
            var column = 0;
            this.tableList = [];
            _.each(this.calendarList, (item) => {
                if (column == 0) {
                    this.tableList.push([]);
                }
                this.tableList[row][column] = item;
                column++;
                if (column == 7) {
                    row++;
                    column = 0;
                }
            });
        },
    },
};
</script>
<style scoped lang="less">
.root {
    max-width: 100%;
}

.month-selector {
    .month {
        font-size: 25px;
        // width: 200px;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
    }
}



.calendar {

    background: white;
    // padding: 20px;
    width: 100%;
    border-collapse: collapse;

    td,
    th {
        // border: 1px solid #f2f2f2;
        vertical-align: top;
        text-align: center;

        // &.cursor:hover {
        //     background: #eee;
        // }
    }

    .calendar-header {
        .header-item {
            padding: 5px 0;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            color: #BFBFBF;

            &.sunday {
                color: #e82929;
            }
        }
    }

    .calendar-body {


        .body-item {
            text-align: left;
            min-height: 60px;
            padding: 2px 2px;

            &.selected {
                background: @primary-color;
                border-radius: 10px;

                .date-label {
                    color: white;

                }

                .date-point {
                    background: white;
                }
            }


            .date-label {
                font-size: 16px;
                font-weight: 400;
                color: #333333;
                text-align: center;

                &.today {
                    color: @primary-color;
                    font-weight: bold;

                }

                // &.selected {
                //     background: @primary-color !important;
                //     // width: 27px;
                //     // height: 27px;
                //     color: white;
                //     border-radius: 50%;
                // }

                &.sunday {
                    color: #e82929;
                }

                &[status="0"] {
                    background: #ff6262;
                    width: 20px;
                    height: 20px;
                    color: white;
                    border-radius: 50%;
                }

                &[status="1"] {
                    background: #eab70d;
                    width: 20px;
                    height: 20px;
                    color: white;
                    border-radius: 50%;
                }

                &[status="2"] {
                    background: #19833d;
                    width: 20px;
                    height: 20px;
                    color: white;
                    border-radius: 50%;
                }
            }

            &.sunday {
                color: #e82929;
            }


        }
    }

    .date-point-container {
        text-align: center;

        .date-point {

            display: inline-block;
            width: 10px;
            height: 10px;
            background: @primary-color;
            ;
            border-radius: 50%;

        }
    }


    .schedule-item {
        cursor: pointer;
        color: black;
        font-size: 14px;
        margin-top: 2px;

        &.deadline {
            color: red;
            font-weight: bold;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}

@media (max-width: 600px) {
    .price {
        font-size: 9px;
    }
}
</style>