<template>
    <div class="root">
        <div v-if="!valid" class="invalid-notice">
            <div class="label">
                <div class="notice-title">잘못된 접근입니다.</div>
                <div class="description">매장에 비치된 QR코드를 인식해주세요</div>
            </div>
        </div>
        <div v-else-if="init">
            <div class="fixed-header">
                <transition>
                    <div class="line1" v-if="headerVisible">
                        <div class="inner-container">
                            <v-layout align-center>
                                <div class="store-name" :style="{ color: getPrimaryColor() }">{{ store.title }}</div>
                                <v-spacer></v-spacer>
                                <div v-if="table" class="table-name">{{ table.name }} 테이블</div>
                            </v-layout>
                        </div>
                    </div>
                </transition>
                <div class="category-list" v-if="categoryList.length > 0">
                    <div class="inner-container">
                        <swiper :freeMode="true" :slidesPerView="'auto'" ref="categorySwiper">
                            <swiper-slide class="category-slide" v-for="(category, key) in getVisibleCategorys" :key="key"
                                :class="{ selected: category.selected }">
                                <div class="menu-title" :style="{ 'border-bottom-color': getPrimaryColor() }"
                                    @click="selectCategory(category, true)">{{ category.title }}</div>
                            </swiper-slide>
                        </swiper>
                    </div>

                </div>

            </div>
            <div class="menu-container">
                <div class="inner-container">
                    <div class="category-list">
                        <div class="category-item" v-for="(category) in getVisibleCategorys" :key="category.id"
                            ref="category">
                            <div class="category-title">
                                {{ category.title }}
                            </div>
                            <div class="menu-list">
                                <div class="menu-item" v-ripple v-for="(menu, key) in category.childs" :key="key"
                                    @click="selectMenu(menu)">
                                    <v-layout fill-height column>
                                        <div>
                                            <v-img :aspect-ratio="1 / 0.8" class="menu-image" v-if="menu.image"
                                                :src="menu.image.imageSUrl"></v-img>
                                            <v-img :aspect-ratio="1 / 0.8" class="menu-image empty" v-else-if="store.image"
                                                :src="store.image.imageSUrl"></v-img>
                                        </div>

                                        <v-flex class="menu-title">{{ menu.title }}</v-flex>
                                        <div class="menu-price">{{ menu.price | number }}원</div>
                                    </v-layout>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bottom-container">
                <div class="inner-container">
                    <div class="order-btn-container">
                        <v-layout>
                            <div class="order-history-btn" v-ripple @click="openOrderDialog">
                                <div class="badge" v-if="getOrderListCountSum(getOrderPreview(tableOrder)) > 0">{{
                                    getOrderListCountSum(getOrderPreview(tableOrder)) }}</div>
                                <div class="label">
                                    주문내역
                                </div>

                            </div>
                            <v-flex class="order-btn" v-ripple @click="order()" :style="{ background: getPrimaryColor() }">
                                <div class="notice-label" v-if="cartList.length > 0">메뉴를 모두 고르셨으면 주문하기를 눌러주세요</div>
                                <div class="badge" v-if="cartList.length > 0">{{ cartList.length }}</div>
                                <div class="label">주문하기</div>
                            </v-flex>
                        </v-layout>

                    </div>

                </div>

            </div>

        </div>


        <!-- 메뉴 다이어로그 -->
        <v-dialog v-model="menuDialog.visible" content-class="menu-dialog" transition="slide-y-reverse-transition">
            <div class="content" v-if="menuDialog.item">
                <v-img class="menu-image" v-if="menuDialog.item.image" :src="menuDialog.item.image.imageMUrl"></v-img>
                <div class="menu-title">{{ menuDialog.item.title }}</div>
                <div class="menu-price">{{ menuDialog.item.price | number }}원</div>
                <div class="menu-description" v-if="menuDialog.item.description"
                    v-html="nl2br(menuDialog.item.description)">
                </div>
                <number-counter v-model="menuDialog.item.count"></number-counter>

                <v-layout class="mt-2" align-center>
                    <div class="mr-2">
                        <div v-ripple @click="closeMenuDialog" class="close-btn">닫기</div>
                        <!-- <v-btn x-large outlined block @click="menuDialog.visible = false">닫기</v-btn> -->
                    </div>
                    <v-flex v-ripple @click="addCart" class="order-btn" :style="{ 'background-color': getPrimaryColor() }">
                        담기
                        <!-- <v-btn x-large color="primary" outlined block @click="menuDialog.visible = false">담기</v-btn> -->
                    </v-flex>

                </v-layout>



            </div>
        </v-dialog>

        <!-- 주문내역 다이어로그 -->
        <v-dialog v-model="orderDialog.visible" width="600px" content-class="order-dialog">
            <v-card class="dialog-content" v-if="tableOrder">
                <div class="order-dialog-header">주문내역</div>

                <v-layout class="order-header">
                    <v-flex class="product">상품명</v-flex>
                    <div class="count">수량</div>
                    <div class="price">금액</div>
                </v-layout>

                <div class="body-container holder">

                    <div class="scroll-container">
                        <v-layout class="order-body" v-for="(orderItem, key) in getOrderPreview(tableOrder)" :key="key">
                            <v-flex class="product">
                                <div>
                                    {{ orderItem.title }}
                                </div>
                                <div class="option" v-for="(option, key) in orderItem.options" :key="key"
                                    v-if="option.valueName">
                                    <div>ㄴ{{ option.valueName }}</div>
                                </div>

                            </v-flex>
                            <div class="count">
                                {{ orderItem.count }}개
                            </div>
                            <div class="price">
                                {{ orderItem.price * orderItem.count | number }}원
                            </div>

                        </v-layout>

                    </div>
                </div>

                <v-layout class="order-footer">
                    <v-flex class="product">합계</v-flex>
                    <div class="count">{{ getOrderListCountSum(getOrderPreview(tableOrder)) }}개</div>
                    <div class="price">{{ getOrderListPriceSum(getOrderPreview(tableOrder)) | number }}원</div>
                </v-layout>


                <div class="close-btn" @click="closeOrderDialog">닫기</div>

            </v-card>
        </v-dialog>
        <transition name="fade">
            <div class="toast-message" v-if="toastMessage">
                {{ toastMessage }}
            </div>
        </transition>
    </div>
</template>

<script>
import _ from "lodash"
import Vue from "vue"

import NumberCounter from "@/components/NumberCounter.vue"
export default {
    components: {
        NumberCounter
    },
    data() {
        return {
            valid: true,
            storeId: null,
            tableId: null,
            init: false,
            store: null,
            categoryList: [],
            table: null,
            cartList: [],
            toastMessage: null,
            tableOrder: null,
            menuDialog: {
                visible: false,
                item: null
            },
            headerVisible: true,
            orderDialog: {
                visible: false
            }
        }
    },
    mounted() {


        // this.storeId = this.$cookies.get("storeId")
        // this.tableId = this.$cookies.get("tableId")
        // if (!this.storeId || !this.tableId) {
        //     this.valid = false
        // }
        console.log(this.$store.state.orderTableOrderId)
        if (this.$store.state.orderTableOrderId) {
            this.getStoreInfo()
        }
        else if (!this.$store.state.orderStoreId || !this.$store.state.orderTableId) {
            this.init = true
            this.valid = false
        }

        else {
            this.getStoreInfo()
        }


        //스크롤 이벤트 감지해서 카테고리 변경될시 swiper 카테고리도 변경
        window.addEventListener("scroll", _.debounce(this.scrollHandler, 100))

        var lastTop = 0
        window.addEventListener("scroll", evt => {

            if (window.scrollY > lastTop) {
                //스크롤 내릴때
                this.setHeaderVisible(false)
            }
            else {
                //스크롤 올릴때
                this.setHeaderVisible(true)
                // this.$store.commit("setHeaderVisible", true)
            }
            lastTop = window.scrollY
        })

    },
    methods: {
        getOrderListCountSum(orderList) {
            return _.reduce(orderList, (memo, orderItem) => { return memo + orderItem.count }, 0)
        },
        getOrderListPriceSum(orderList) {
            return _.reduce(orderList, (memo, orderItem) => {
                let total = memo;
                let optionPrice = 0
                _.each(orderItem.options, option => {
                    optionPrice += option.price
                })
                total += (orderItem.price + optionPrice) * orderItem.count
                return total
            }, 0)
        },
        closeOrderDialog() {
            this.orderDialog.visible = false

        },
        openOrderDialog() {
            this.orderDialog.visible = true
        },
        /**
         * 테이블 상세 다이어로그에서 테이블의 주문된것과 주문되지않은걸 합쳐서 보여줌
         * @param {*} table 
         * @param {*} order 
         */
        getOrderPreview(tableOrder, order) {
            let resultOrderList = []

            //기주문된 테이블오더를 정리함

            _.each(tableOrder.orders, order => {
                _.each(order.items, item => {
                    let finded = _.find(resultOrderList, resultItem => {
                        let compared = this.compareItem(item, resultItem)
                        if (compared) {
                            resultItem.count += item.count
                        }
                        return compared
                    })
                    // if (finded) {
                    //     console.log(finded)
                    //     finded += item.count
                    // }
                    if (!finded) {
                        resultOrderList.push(_.cloneDeep(item))
                    }
                })
            })

            //새로 주문한 주문도 추가함
            if (order) {

                _.each(order.items, item => {
                    let finded = _.find(resultOrderList, resultItem => {
                        let compared = this.compareItem(item, resultItem)

                        if (compared) {
                            resultItem.count += item.count
                            return true
                        }
                    })
                    if (!finded) {
                        resultOrderList.push(_.cloneDeep(item))
                    }
                })
            }

            return resultOrderList

        },

        /**
         * 두개의 아이템을 받아서 같은 메뉴일경우 true, 다를경우 false
         * @param {*} item1 
         * @param {*} item2 
         */
        compareItem(item1, item2) {

            if (item1.menuItemId && item2.menuItemId && item1.menuItemId != item2.menuItemId) {
                return false
            }
            if (item1.itemId && item2.itemId && item1.itemId != item2.itemId) {
                return false
            }

            if (item1.canceled != item2.canceled) {
                return false
            }

            if (item1.options.length != item2.options.length) {
                return false
            }
            if (item1.options.length == 0) {
                return true
            }

            let notEqualOption = _.find(item1.options, (option, key) => {
                if (option.valueId != item2.options[key].valueId) {
                    return true
                }
            })
            if (!notEqualOption) {
                return false;
            }

            return true
        },
        setHeaderVisible(visible) {
            this.headerVisible = visible
        },
        toast(message) {
            this.toastMessage = message
            setTimeout(() => {
                this.toastMessage = null
            }, 2000)
        },
        order() {

            if (this.cartList.length == 0) {
                this.toast("메뉴를 선택해주세요")
                return
            }
            let order = {
                storeId: this.store.id,
                tableId: this.table.id,
                tableOrderId: this.$store.state.orderTableOrderId,
                items: this.cartList,


            }

            this.$axios.post("/order/complete", order)
                .then(result => {
                    if (result.data.res) {

                        this.tableOrder = result.data.tableOrder
                        this.toast("주문이 완료되었습니다.")
                        this.cartList = []
                    }
                    else {
                        this.toast(result.data.message || result.data.msg)
                    }
                })
        },
        addCart() {
            this.cartList.push({
                ...this.menuDialog.item
            })
            this.closeMenuDialog()
        },
        closeMenuDialog() {
            this.menuDialog.visible = false
        },
        nl2br(str) {
            return str.replace(/(?:\r\n|\r|\n)/g, '<br />');
        },
        selectMenu(menu) {
            this.menuDialog.item = {
                title: menu.title,
                price: menu.price,
                options: menu.options,
                menuItemId: menu.id,
                itemId: menu.id,
                count: 1
            }
            this.menuDialog.visible = true
        },
        scrollHandler() {
            let categoryElementList = this.$refs.category

            let findedEl = _.findLast(categoryElementList, categoryEl => {

                if (window.scrollY > categoryEl.offsetTop - 91) {
                    return true
                }
            })
            if (findedEl) {
                let index = _.indexOf(categoryElementList, findedEl)
                this.selectCategory(this.getVisibleCategorys[index], false)

                this.$refs.categorySwiper.swiperRef.slideTo(index, 300, false)
            }


        },
        getPrimaryColor() {
            if (this.store && this.store.primaryColor) {
                return this.store.primaryColor
            }
            else {
                return "#000000"
            }
        },
        getStoreInfo() {
            this.$axios.post("/order/create", {
                tableOrderId: this.$store.state.orderTableOrderId,
                storeId: this.$store.state.orderStoreId,
                tableId: this.$store.state.orderTableId,
            })
                .then(result => {
                    if (result.data.res) {
                        this.init = true
                        this.store = result.data.store
                        this.categoryList = result.data.menuList
                        this.table = result.data.table
                        this.tableOrder = result.data.tableOrder

                        if (this.getVisibleCategorys.length > 0) {
                            let firstCategory = _.first(this.getVisibleCategorys)
                            if (firstCategory) {
                                this.selectCategory(firstCategory)

                            }
                        }
                    }
                    else {
                        this.valid = false
                        this.init = true
                    }
                })
        },
        /**
         * 카테고리 선택시 선택된 카테고리로 스크롤 이동
         */
        selectCategory(category, scrolled) {
            _.each(this.categoryList, category => {
                category.selected = false
            })
            category.selected = true
            if (scrolled === false) {
                return
            }
            //현재 선택된 카테고리가 몇번째 인덱스인지 체크하기
            let index = _.findIndex(this.categoryList, category => category.selected)
            //현재 선택된 index로 스크롤 이동
            //offset :90px
            if (index > -1) {
                Vue.nextTick(() => {
                    let categoryElement = this.$refs.category[index]
                    if (categoryElement) {
                        let offset = categoryElement.offsetTop - 90
                        window.scrollTo({
                            top: offset,
                            behavior: "smooth"
                        })
                    }
                })

            }



        }
    }

}
</script>

<style scoped lang="less">
.root {
    background: white;
    min-height: 100%;
    width: 100%;
}

.invalid-notice {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .label {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        .notice-title {
            font-size: 40px;
            font-weight: 700;
            white-space: nowrap;
            margin-bottom: 20px;
        }

        .description {
            font-size: 20px;
            font-weight: 500;
        }
    }
}

.inner-container {
    width: 800px;
    max-width: 100%;
    margin: 0 auto;
}

.fixed-header {
    // padding-top: 5px;
    // padding-bottom: 5px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    .line1 {
        padding-left: 15px;
        padding-right: 15px;

        .store-name {
            font-size: 25px;
            font-weight: 700;
        }

        .table-name {
            font-size: 20px;
            font-weight: 700;
        }
    }

    .category-list {

        padding-left: 5px;


        .category-slide {
            width: initial;
            margin-top: 10px;

            &.selected {
                .menu-title {
                    font-weight: 900;
                    color: black;
                    border-bottom: 2px solid;
                }
            }

            .menu-title {
                font-size: 20px;
                font-weight: 500;
                padding: 5px 20px;
                cursor: pointer;
                border-radius: 10px 10px 0 0;

            }

        }
    }
}

.menu-container {
    padding-top: 90px;
    padding-bottom: 50vh;

    .category-list {
        .category-item {
            padding: 10px;
        }

        .category-title {
            font-size: 25px;
            font-weight: 700;

        }

        .menu-list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;


            .menu-item {
                margin-bottom: 10px;
                border-radius: 5px;

                .menu-image {
                    width: 100%;

                    &.empty {
                        //이미지 흑백으로 처리
                        opacity: 0.3;
                        //흑백으로 보이게하기
                        filter: grayscale(100%);
                    }
                }

                .menu-title {
                    font-size: 18px;
                    font-weight: 500;
                    padding: 3px 5px;
                    border-radius: 10px;
                    cursor: pointer;
                }

                .menu-price {
                    font-size: 20px;
                    font-weight: 700;
                    padding: 3px 5px;
                    border-radius: 10px;
                    cursor: pointer;
                }
            }
        }
    }
}

.bottom-container {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;


    .order-btn-container {
        padding: 10px;
        background: #ffffff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .order-history-btn {
        font-size: 20px;
        font-weight: 700;
        padding: 10px 20px;
        border-radius: 10px;
        text-align: center;
        border: 1px solid #000000;
        cursor: pointer;
        margin-right: 10px;
        position: relative;
    }

    .order-btn {
        font-size: 20px;
        font-weight: 700;
        padding: 10px 20px;
        border-radius: 10px;
        cursor: pointer;

        color: #ffffff;
        text-align: center;
        position: relative;

        // 버튼 상단에 말풍선 형태로 뜨게하기
        .notice-label {
            position: absolute;
            top: -35px;
            left: 50%;
            transform: translateX(-50%);
            background: #ffffff;
            color: black;
            padding: 5px 10px;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
            font-size: 14px;
            font-weight: 500;
            white-space: nowrap;
        }


    }

    .badge {
        position: absolute;
        top: -20px;
        right: 10px;
        width: 30px;
        height: 30px;
        border-radius: 5px;
        background: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

        color: black;
        font-size: 20px;
        padding: 5px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}


.menu-dialog {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    .content {
        padding: 20px;
        background: white;
        border-radius: 20px 20px 0 0;

        .menu-image {
            width: 100%;
            border-radius: 20px;


        }

        .menu-title {
            font-size: 25px;
            font-weight: 700;
        }

        .menu-price {
            font-size: 20px;
            font-weight: 700;
        }

        .menu-description {
            font-size: 18px;
            font-weight: 500;
        }

        .close-btn {
            font-size: 20px;
            font-weight: 700;
            padding: 10px 20px;
            border-radius: 10px;
            text-align: center;
            border: 1px solid #000000;
            cursor: pointer;
        }

        .order-btn {
            font-size: 20px;
            font-weight: 700;
            padding: 10px 20px;
            border-radius: 10px;
            cursor: pointer;

            color: #ffffff;
            text-align: center;
        }
    }
}

.slide-up {
    transition: all 0.3s ease-in-out;
    transform: translateY(100%);
}

.toast-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 20px;
    font-size: 25px;
    font-weight: 700;
    border-radius: 10px;
    z-index: 999;
    white-space: nowrap;
}

.order-dialog {
    .dialog-content {
        padding: 20px;
    }

    .order-dialog-header {
        font-size: 23px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .body-container {
        height: 300px;
    }

    .order-header,
    .order-body,
    .order-footer {
        font-size: 20px;
        padding-top: 5px;
        padding-bottom: 5px;


        .count {
            width: 80px;
            text-align: center;
        }

        .price {
            width: 120px;
            text-align: center;
        }
    }

    .order-header,
    .order-footer {
        font-weight: 700;

    }

    .order-header {
        border-bottom: 1px solid #ccc;
    }

    .order-body {
        .count {
            text-align: center;
        }

        .price {
            text-align: right;
        }
    }


    .order-footer {
        border-top: 1px solid #ccc;

        .price {
            text-align: right;
        }
    }



    .close-btn {
        margin-top: 20px;
        border: 1px solid #ccc;
        padding: 10px 20px;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
}

//.slide-up 애니메이션
.slide-up-enter-active,
.slide-up-leave-active {
    transition: all 0.3s ease-in-out;
}

.slide-up-enter,
.slide-up-leave-to {
    transform: translateY(-100%);
}
</style>