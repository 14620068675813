<template>
    <div class="root">
        <div class="section-box">
            <div class="header">매장목록</div>

            <table class="table">
                <tr>
                    <th>
                        매장이름
                    </th>

                    <th>
                        매장주소
                    </th>
                    <th width="150px">
                        사업자등록본호
                    </th>
                    <th width="120px">
                        생성일
                    </th>
                </tr>
                <tbody>
                    <tr v-for="(store, key) in list" :key="key" class="cursor" @click="moveStore(store)">
                        <td>
                            {{ store.title }}
                        </td>
                        <td>
                            {{ store.address }}
                        </td>
                        <td class="text-center">
                            {{ store.registNumber }}
                        </td>
                        <td class="text-center">
                            {{ store.createDate | date("YYYY-MM-DD") }}
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: []
        }
    },
    mounted() {
        this.getStoreList()
    },
    methods: {
        moveStore(store) {
            this.$router.push(`/root/tableorder/${store.id}`)
        },
        async getStoreList() {
            var { data } = await this.$axios.post("/root/store/list");
            this.list = data.list;
        },
    }
}
</script>

<style scoped lang="less">
.root {
    width: 1100px;
}
</style>