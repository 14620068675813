<template>
    <div class="root">
        <div class="section-box">
            <div class="section-header">
                결제수단별 매출
            </div>
            <v-layout align-center class="mt-2">
                <div>
                    <date-picker v-model="filter.startDate"></date-picker>
                </div>
                <div>
                    <date-picker v-model="filter.endDate"></date-picker>
                </div>

                <v-spacer></v-spacer>
                <v-btn @click="search()" depressed color="primary">조회</v-btn>
            </v-layout>
        </div>
        <div class="section-box mt-2" v-if="incomeChart && incomesByMenu.length > 0">
            <div class="chart-container">
                <pie-chart :data="incomeChart" :colors="colors"></pie-chart>
            </div>
        </div>
        <div class="section-box mt-2">
            <div class="header">총 매출액</div>
            <div class="value">
                {{ totalPrice | number }}원
            </div>
            <div class="header">총 결제건수</div>
            <div class="value">
                {{ totalCount | number }}건
            </div>
        </div>
        <div class="list">
            <div class="item" v-for="(item, key) in incomesByMethod" :key="key">
                <v-layout align-center>
                    <v-flex class="rate">
                        {{ item.method | orderMethod }}
                    </v-flex>

                    <div class="text-right">
                        <div class="price">
                            {{ item.totalPrice | number }}원
                        </div>
                        <div class="count">
                            {{ item.count }}건
                        </div>

                    </div>
                </v-layout>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import _ from "lodash"
export default {
    data() {
        return {
            filter: {
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
            incomesByMethod: [],
            total: null,
            incomeChart: null,
            colors: []
        }
    },
    mounted() {
        this.search()
    },
    computed: {
        totalCount() {
            return _.sumBy(this.incomesByMethod, (item) => {
                return item.count
            })
        },
        totalPrice() {
            return _.sumBy(this.incomesByMethod, (item) => {
                return item.totalPrice
            })
        }
    },
    methods: {
        sortByPrice() {
            return _.sortBy(this.incomesByMethod, (item) => {
                return item.totalPrice * item.count
            }).reverse()
        },
        search() {
            this.$axios.post("/pos/incomes/method", this.filter).then(res => {

                this.incomesByMethod = res.data.incomesByMethod

                this.total = res.data.total
                this.colors = []


                // {
                //     labels: _.map(this.incomesByMenu, (item) => {
                //         return item.title
                //     }),
                //     datas: [
                //         {
                //             name: "매출",
                //             data: _.map(this.incomesByMenu, (item) => {
                //                 return item.price;
                //             }),
                //         },
                //     ],
                // };

            })
        }
    }
}
</script>

<style scoped lang="less">
.list {
    .item {
        .rate {
            font-size: 20px;
            font-weight: 700;
            margin-right: 20px;
        }

        .price {
            font-size: 20px;
            font-weight: 700;
        }
    }
}

.header {
    font-size: 20px;
    text-align: center;
}

.value {
    font-size: 25px;
    font-weight: 700;
    text-align: center;

}
</style>