<template>
    <div>
        <div class="section-box">
            <div class="text-right">
                <v-btn @click="openOptionDialog()" outlined color="primary">옵션추가</v-btn>
            </div>
            <div class="option-list">
                <div class="option-item" v-for="(option, key) in optionList" :key="key">
                    <v-layout align-center>
                        <div>
                            {{ option.name }}
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="openOptionDialog(option)">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon @click="removeOption(option)" color="error">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-layout>

                </div>
            </div>
        </div>
        <v-dialog v-model="optionDialog.visible" width="600px">
            <v-card class="pa-4" v-if="optionDialog.item">
                <div class="input-header">이용자에게 보이는 이름</div>
                <hh-input class="input" v-model="optionDialog.item.displayName"></hh-input>
                <div class="input-header">시스템상의 이름</div>
                <hh-input class="input" v-model="optionDialog.item.name"></hh-input>
                <div class="input-header">옵션타입</div>
                <v-select v-model="optionDialog.item.type" :items="optionTypes" solo flat hide-details
                    class="input"></v-select>
                <v-layout align-center class="mt-4 input-header">
                    <div>항목</div>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="addOptionItem(optionDialog.item.items)">추가</v-btn>
                </v-layout>
                <div class="option-list mt-4">
                    <draggable v-model="optionDialog.item.items" handle=".handle">
                        <div v-for="(optionItem, key) in optionDialog.item.items" :key="key" v-if="!optionItem.deleted">
                            <v-layout>
                                <v-icon class="handle">mdi-menu</v-icon>
                                <hh-input placeholder="옵션이름" v-model="optionItem.name"></hh-input>
                                <hh-money placeholder="옵션가격" v-model="optionItem.price"></hh-money>
                                <v-btn icon color="error" @click="removeOptionItem(optionItem)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-layout>
                        </div>

                    </draggable>
                </div>
                <div class="text-center">
                    <v-btn color="primary" @click="optionSubmit">
                        <div v-if="optionDialog.item.id">
                            수정
                        </div>
                        <div v-else>
                            추가
                        </div>
                    </v-btn>
                </div>

            </v-card>
        </v-dialog>

    </div>
</template>

<script>
export default {
    data() {
        return {
            optionList: [],
            optionDialog: {
                visible: false,
                item: null
            },
            optionTypes: [
                { text: "단일선택", "value": 1 },
                { text: "다중선택", "value": 2 }
            ]
        }
    },
    mounted() {
        this.getOptionList()
    },
    methods: {
        removeOptionItem(optionItem) {
            if (!window.confirm("삭제하시겠습니까?")) {
                return
            }
            this.$axios.post("/option/item/remove", { id: optionItem.id })
                .then(result => {
                    if (result.data.res) {
                        optionItem.deleted = true
                        // this.getOptionList()
                    }
                })
        },
        removeOption(option) {
            if (window.confirm("삭제하시겠습니까?")) {
                this.$axios.post("/option/remove", { id: option.id })
                    .then(result => {
                        if (result.data.res) {
                            this.getOptionList()
                        }
                    })
            }
        },
        addOptionItem(items) {
            items.push({
                name: "",
                price: 0

            })
        },
        openOptionDialog(option) {
            this.optionDialog.item = option || {
                name: "",
                displayName: "",
                type: 1,
                priece: 0,
                items: [],
                storeId: this.$store.state.storeInfo.id
            };
            this.optionDialog.visible = true;
        },
        getOptionList() {
            this.$axios.post("/option/list")
                .then(result => {
                    if (result.data.res) {
                        this.optionList = result.data.list;
                    }
                })
        },
        optionSubmit() {
            this.$axios.post("/option/submit", this.optionDialog.item)
                .then((result) => {
                    if (result.data.res) {
                        window.alert("추가되었습니다")
                        this.optionDialog.visible = false;
                        this.getOptionList()
                    }
                })
        },
    }
}
</script>

<style scoped lang="less">
.section-box {
    width: 800px;
}
</style>