import { render, staticRenderFns } from "./method.vue?vue&type=template&id=41f5a366&scoped=true"
import script from "./method.vue?vue&type=script&lang=js"
export * from "./method.vue?vue&type=script&lang=js"
import style0 from "./method.vue?vue&type=style&index=0&id=41f5a366&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41f5a366",
  null
  
)

export default component.exports