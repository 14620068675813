<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
  export default {
    data() {
      return {
        expend: false,
        drawer: false,
      };
    },
    watch: {
      "$store.state.loginCallCount": function () {
        this.kakaoLogin();
      },
    },

    mounted() {
      if (!Kakao.isInitialized()) {
        Kakao.init("fd1629d47aec23271c1076ceb6cd9a56");
      }

      this.getUserInfo();
    },
    methods: {
      moveTab(path) {
        this.$router.push(path);
      },
      menuExpend() {
        this.expend = true;
      },
      menuUnexpend() {
        this.expend = false;
      },
      move(path) {
        this.drawer = false;
        this.$router.push(path);
      },
      goAdmin() {
        this.$router.push("/admin");
      },
      goMypage() {
        this.$router.push("/mypage");
      },
      logout() {
        this.$axios.post("/user/logout").then((result) => {
          if (result.data.res) {
            this.$store.commit("logout");
            this.$router.replace("/");
          }
        });
      },
      getUserInfo() {
        this.$axios.post("/user/info").then((result) => {
          if (result.data.res) {
            this.$store.commit("setUserInfo", result.data.user);
          }
        });
      },
      kakaoLogin(path) {
        path = this.$store.state.loginRedirect || "/";
        Kakao.Auth.authorize({
          redirectUri: `${location.protocol}//${location.host}/api/v1/kakao/authorize`,
          scope: "profile_nickname,profile_image,account_email",
          state: `${encodeURIComponent(path)}`,
        });
      },
    },
  };
</script>

<style lang="less">
  @import "./assets/const.less";

  @primary-color: #ffa600;
  @sub-color: #ffd077;
  @manager-color: #ffa600;
  @background-color: #F7F7F7;
  @border-color: #ccc;
  @manager-container-width: 400px;
  @inner-width: 1100px;
  @shadow-color: #BAC0CC44;

  .cursor {
    cursor: pointer;
  }

  html,
  body,
  #app {
    height: 100%;

    background: white;
  }

  * {
    font-family: "Noto Sans KR", sans-serif;
  }

  // .nuxt-holder {
  //   position: relative;
  //   .nuxt-container {
  //     position: absolute;
  //     left: 0;
  //     right: 0;
  //     top: 0;
  //     bottom: 0;
  //   }
  // }

  .input {
    border: 1px solid @border-color;
  }

  .input-header {
    margin-top: 10px;
    margin-bottom: 1px;
    margin-left: 3px;
    font-size: 14px;
    font-weight: bold;
  }

  .table-header {
    margin-top: 10px;
    margin-bottom: 3px;

    font-size: 18px;
    font-weight: bold;
  }

  .description {
    img {
      width: 100% !important;
    }
  }

  .inner-container {
    width: @inner-width;
    margin: 0 auto;
    max-width: 100%;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .text-right {
    input[type="number"] {
      text-align: right;
    }
  }

  .text-right.number {
    input {
      text-align: right;
    }
  }

  .tab-list {
    .tab-item {
      width: 20%;
      cursor: pointer;
      text-align: center;
      font-size: 20px;
      padding-top: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid @border-color;

      &.active {
        border-bottom: 3px solid @primary-color;
      }
    }
  }

  .section {
    background: white;
    box-shadow: 0 0 6px #ddd;
    padding: 20px;
    margin-bottom: 15px;

    .section-header {
      font-size: 25px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .section-subheader {
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: bold;
    }
  }

  .input-container {
    .theme--light.v-text-field--solo>.v-input__control>.v-input__slot {
      background: inherit;
    }
  }

  .tab-blank {
    height: 55px;
  }

  .tab-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 100;
    border-top: 1px solid #ccc;

    .tab-item {
      text-align: center;
      padding-top: 5px;
      padding-bottom: 5px;

      &.active {
        color: @primary-color;
        font-weight: bold;

        .tab-icon {
          color: @primary-color;
        }
      }

      .tab-label {
        font-size: 12px;
      }
    }
  }

  .v-application p {
    margin-bottom: 0;
  }

  .flat.v-text-field.v-text-field--solo .v-input__control {
    min-height: 0;
    padding: 0;

    textarea {
      margin: 0;
    }
  }

  .flat-checkbox {
    margin: 0 !important;
    padding: 0 !important;
    display: inline-block !important;

    .v-input--selection-controls__input {
      margin: 0 !important;
      padding: 0 !important;
    }
  }

  .v-list-item--dense,
  .v-list--dense .v-list-item {
    min-height: 0;
  }

  .v-list-item__content {
    padding: 5px 0 !important;
  }

  .section-box {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 3px 6px @shadow-color;
    border: 1px solid #eee;

    .section-header {
      font-size: 20px;
      font-weight: 700;
    }

    .section-subheader {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .dialog-header {
    text-align: center;
    font-size: 25px;
  }

  .notice-box {
    background: #c1ffc8;
    border: 1px solid #4caf50;
    padding: 3px 5px;
    font-size: 14px;
    border-radius: 5px;
    color: #4caf50;
  }

  .section-box {
    max-width: 100%;
    margin: 0 auto;
  }

  @media screen and (max-width: 1100px) {
    .nuxt-container {
      margin-top: 52px;
    }

    .app-header {
      padding: 10px 15px;
    }

    .table {
      font-size: 13px;
      padding: 0;
    }

    .section-box {
      padding: 1w5px;
    }
  }

  .v-skeleton-loader__image {
    height: 100%;
  }

  .menu-dialog {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 !important;
    padding: 0 !important;
  }

  .root {
    width: 600px;
    max-width: 100%;
    margin: 0 auto;


  }

  table.table {
    width: 100%;
    border-collapse: collapse;
    background: white;

    tr.cursor {
      &:hover {
        background: #eee;
      }
    }

    td,
    th {
      border: 1px solid @border-color;
      padding: 3px 5px;
    }
  }

  .filter-container {
    padding: 10px;
    border-radius: 10px;
    background: #f5f5f5;
    margin-bottom: 10px;

    .input-header {
      margin-top: 0;
    }
  }

  .holder {
    height: 100%;
    width: 100%;
    position: relative;

    .hold-item {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  }

  .tab-container {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    z-index: 100;
    border-top: 1px solid #ccc;

    .tab-item {
      text-align: center;
      padding-top: 5px;
      padding-bottom: 5px;

      &.active {
        color: @primary-color;
        font-weight: bold;

        .tab-icon {
          color: @primary-color;
        }
      }

      .tab-label {
        font-size: 12px;
      }
    }
  }

  .list {
    .item {
      background: white;
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 20px;
      border-radius: 15px;
      box-shadow: 0 0 6px #ddd;
      cursor: pointer;
    }
  }



  @handle-size: 10px;

  .table-item {
    .handle {
      width: 10px;
      height: 10px;
      background: white;
      outline: 1px solid #ccc;
      position: absolute;
      right: 0;
      bottom: 0;

      &.handle-tl {
        cursor: nwse-resize;
        top: -1 * @handle-size;
        left: -1 * @handle-size;
      }

      &.handle-tm {
        cursor: ns-resize;
        top: -1 * @handle-size;
        left: 50% - (@handle-size / 2);
      }

      &.handle-tr {
        cursor: nwse-resize;
        top: -1 * @handle-size;
        right: -1 * @handle-size;
      }

      &.handle-ml {
        cursor: ew-resize;
        top: 50% - (@handle-size / 2);
        left: -1 * @handle-size;
      }

      &.handle-mr {
        cursor: nwse-resize;
        top: 50% - (@handle-size / 2);
        right: -1 * @handle-size;
      }



      &.handle-bl {
        cursor: nwse-resize;
        bottom: -1 * @handle-size;
        left: -1 * @handle-size;
      }

      &.handle-bm {
        cursor: ns-resize;
        bottom: -1 * @handle-size;
        left: 50% - (@handle-size / 2);
      }

      &.handle-br {
        cursor: nwse-resize;
        bottom: -1 * @handle-size;
        right: -1 * @handle-size;
      }

    }
  }

</style>
