import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: "#f9b724",
                accent: "#ffa600",
                secondary: "#ffa600",
            }
        }
    }

});
