<template>
    <div>
        <div class="section-box">
            <div class="text-right">
                <v-btn @click="addCategory" outlined color="primary">카테고리추가</v-btn>
            </div>
            <div class="category-list">
                <draggable v-model="categoryList" handle=".handle" @change="reorderCategory(categoryList)">
                    <div class="category-item" v-for="category in categoryList" :key="category.id">
                        <v-layout align-center>
                            <!-- <v-btn icon @click="category.expand = !category.expand">
                                <v-icon v-if="category.expand">mdi-chevron-up</v-icon>
                                <v-icon v-else>mdi-chevron-down</v-icon>
                            </v-btn> -->
                            <v-icon class="handle">mdi-menu</v-icon>
                            <hh-input v-model="category.title"></hh-input>
                            <!-- <v-btn icon color="primary" @click="openItemDialog(category)">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn> -->
                            <v-btn icon @click="modifyCategory(category)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn icon color="error" @click="removeCategory(category)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-layout>
                    </div>
                </draggable>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
    components: {
        draggable
    },
    data() {
        return {
            categoryList: []
        }
    },
    mounted() {
        this.getCategoryList()
    },
    methods: {
        reorderCategory(list) {
            console.log(list);
            _.each(list, (item, key) => {
                item.order = key;
            });
            this.$axios.post("/category/reorder", {
                list: list,
            });
        },
        getCategoryList() {
            this.$axios.post("/category/list")
                .then(result => {
                    if (result.data.res) {
                        this.categoryList = result.data.list
                    }
                })
        },
        modifyCategory(category) {
            if (!confirm("정말 수정하시겠습니까?")) {
                return;
            }
            this.$axios.post("/category/modify", category)
                .then((result) => {
                    if (result.data.res) {
                        window.alert("수정되었습니다")
                        this.getCategoryList();
                    }

                });

        },
        removeCategory(category) {
            if (!window.confirm("정말 삭제하시겠습니까?")) {
                return
            }
            this.$axios.post("/category/remove", category)
                .then(result => {
                    if (result.data.res) {
                        window.alert("삭제되었습니다")
                        this.getCategoryList();
                    }
                })
        },
        addCategory() {
            var title = window.prompt("카테고리 제목을 입력해주세요");
            if (title) {
                this.$axios
                    .post("/category/add", {
                        title: title,
                        storeId: this.$store.state.storeInfo.id
                    })
                    .then((result) => {
                        this.getCategoryList();
                    });
            }
        },
    }
}
</script>

<style scoped lang="less">
.section-box {
    width: 800px;
    margin: 0 auto;
}
</style>