<template>
    <div class="root">
        <div class="section-box">

            <v-layout>
                <div class="section-header">테이블목록</div>
                <v-spacer></v-spacer>
                <v-btn color="primary" outlined @click="openTableDialog">추가</v-btn>
            </v-layout>

            <table class="table mt-2">
                <thead>
                    <tr>
                        <th width="80px">테이블번호</th>
                        <th>기기ID</th>
                        <th width="150px">로그인시간</th>
                        <th width="120px">생성시간</th>
                        <th width="120px">선결제여부</th>
                        <td width="250px">
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(table, key) in getSortedTable" :key="key">
                        <td>

                            <hh-input v-model="table.name"></hh-input>
                        </td>
                        <td>

                            <v-select solo flat hide-details v-model="table.deviceId" item-value="id" item-text="deviceId"
                                :items="deviceList"></v-select>
                            <div v-if="table.device">

                                <!-- {{ table.device.deviceId }} -->
                            </div>

                        </td>
                        <td>
                            <div v-if="table.device">
                                {{ table.device.lastLoginDate | date("YYYY-MM-DD HH:mm") }}
                            </div>
                        </td>

                        <td>
                            {{ table.createDate | date("YYYY-MM-DD") }}
                        </td>
                        <td>
                            <div v-if="table.device">
                                <v-switch v-model="table.device.prePaid"></v-switch>
                            </div>

                        </td>
                        <td class='text-center'>
                            <v-btn color="primary" outlined small @click="openOrderLink(table)">주문링크</v-btn>
                            <v-btn color="primary" outlined small @click="modifyTable(table)">수정</v-btn>
                            <v-btn color="red" outlined small @click="removeTabl(table)">삭제</v-btn>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="section-header">미지정 리스트</div>
            <table class="table">
                <thead>
                    <tr>
                        <th width="150px">테이블번호</th>
                        <th>기기ID</th>
                        <th width="150px">로그인시간</th>
                        <th width="120px">생성시간</th>
                        <td width="150px">
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(device, key) in deviceList" :key="key" v-if="!device.table">
                        <td>

                            <hh-input v-model="device.tableName" dense></hh-input>
                        </td>
                        <td>
                            <div v-if="device">
                                {{ device.deviceId }}
                            </div>
                        </td>
                        <td class="text-center">
                            {{ device.lastLoginDate | date("YYYY-MM-DD HH:mm") }}
                        </td>
                        <td class="text-center">
                            {{ device.createDate | date("YYYY-MM-DD") }}
                        </td>
                        <td class='text-center'>
                            <v-btn color="primary" outlined small @click="addTable(device)">테이블지정</v-btn>
                            <!-- 
                            <v-btn color="red" outlined small @click="removeTabl(table)">삭제</v-btn> -->
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <v-dialog width="500px" v-model="tableDialog.visible" content-class="table-dialog">
            <v-card class="content" v-if="tableDialog.table">
                <div class="input-header">
                    테이블번호
                </div>
                <hh-money v-model="tableDialog.table.name" class="input"></hh-money>
                <div class="input-header">
                    기기ID
                </div>
                <v-select v-model="tableDialog.table.deviceId" :items="notSelectedDeviceList" class="input" solo flat
                    hide-details item-value="id" item-text="visibleText"></v-select>
                <div class="text-center mt-2">
                    <v-btn @click="submit">추가</v-btn>
                </div>
            </v-card>

        </v-dialog>
    </div>
</template>

<script>
import _ from "lodash"
import moment from "moment"
export default {
    data() {
        return {
            tableList: [],
            deviceList: [],
            tableDialog: {
                visible: false,
                table: null
            }
        }
    },
    computed: {
        getSortedTable() {
            return _.chain(this.tableList)
                .sortBy(table => {
                    if (_.isNaN(Number(table.name))) {
                        try {
                            var matchNumber = table.name.match(/(\d+)/g)
                            if (matchNumber) {
                                return 99999 + Number(matchNumber[0])
                            }
                        } catch (err) { }


                        return 99999
                    }

                    else {
                        return Number(table.name)
                    }
                }).value()
        },
        notSelectedDeviceList() {
            return _.chain(this.deviceList)
                .map(device => {
                    return {
                        id: device.id,
                        deviceId: device.deviceId,
                        tableId: device.tableId,
                        table: device.table,
                        visibleText: `${device.deviceId}(${moment(device.createDate).format('YYYY-MM-DD HH:mm')})`,
                        createDate: device.createDate
                    }
                })
                .filter(device => {
                    console.log(device)
                    var finded = _.find(this.tableList, table => table.deviceId == device.id)
                    return !finded

                })
                .sortBy('createDate')
                .reverse()
                .value()

        }
    },
    mounted() {
        this.getTableOrderList()
    },
    methods: {
        modifyTable(table) {
            this.$axios.post("/root/tableorder/modify", table).then(result => {
                if (result.data.res) {
                    window.alert('수정되었습니다')
                    this.getTableOrderList()
                }
            })
        },
        openOrderLink(table) {
            window.open(`/order/${this.$route.params.id}/${table.id}`)

        },
        addTable(device) {
            if (!device.tableName) {
                alert('테이블번호를 입력해주세요')
                return
            }
            this.$axios.post('/root/tableorder/add', {
                storeId: this.$route.params.id,
                table: {
                    name: device.tableName,
                    deviceId: device.id
                }
            }).then(result => {
                if (result.data.res) {
                    this.getTableOrderList()
                }
            })
        },
        removeTabl(table) {
            if (!confirm('삭제하시겠습니까?')) {
                return
            }
            this.$axios.post('/root/tableorder/remove', {
                id: table.id
            }).then(result => {
                if (result.data.res) {
                    this.getTableOrderList()
                }
            })
        },
        submit() {
            this.$axios.post('/root/tableorder/add', {
                storeId: this.$route.params.id,
                table: this.tableDialog.table
            }).then(result => {
                if (result.data.res) {
                    this.getTableOrderList()
                    this.tableDialog.visible = false
                }
            })
        },
        openTableDialog() {
            this.tableDialog.table = {
                name: '',
                deviceId: null
            }
            this.tableDialog.visible = true
        },
        getTableOrderList() {
            this.$axios.post('/root/tableorder/list', {
                storeId: this.$route.params.id
            }).then(result => {

                if (result.data.res) {
                    this.tableList = result.data.tableList
                    this.deviceList = result.data.deviceList
                }
            })
        }
    }


}
</script>

<style scoped lang="less">
.root {
    width: 1000px;
}

.table-dialog {
    overflow: hidden;

    .content {
        overflow: hidden;
        padding: 20px;
    }
}
</style>