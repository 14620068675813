<template>
    <div class="table-manage-root">
        <div class="text-right">
            <v-btn outlined color="primary" @click="save">저장</v-btn>
        </div>

        <v-layout>
            <div>
                <div class="area-list-container">

                    <draggable v-model="areas" class="draggable-container">
                        <div class="area-item" :class="{ active: selectedArea && area.id == selectedArea.id }"
                            @click="selectArea(area)" v-for="(area, key) in areas" :key="key">
                            <v-layout align-center>
                                <v-flex class="mr-4">{{ area.name }}</v-flex>
                                <v-icon @click.stop="removeArea(area)" size="20px">mdi-close</v-icon>
                            </v-layout>
                        </div>
                    </draggable>
                    <div class="area-item" @click="openAreaDialog">+추가</div>
                </div>
                <div class="area-container" ref="container">
                    <div v-if="selectedArea" style="width:100%;height:100%;">
                        <vue-draggable-resizable class="table-item" class-name-active="active"
                            :class="{ active: isActiveTableItem(table), hidden: table.hidden }"
                            v-for="(table, key) in selectedArea.tables" :key="key" :x="table.AreaTable.x"
                            :y="table.AreaTable.y" :w="table.AreaTable.width" :h="table.AreaTable.height" :minHeight="30"
                            :minWidth="30" @activated="onActivated(table)" :parent="true" @dragging="onDrag"
                            v-if="table && table.AreaTable && !table.AreaTable.deleted" @resizing="onResize">
                            <p>{{ table.name }}</p>

                        </vue-draggable-resizable>
                    </div>


                </div>
            </div>

            <div class="function-container">
                <v-btn outlined block color="primary" @click="openTableDialog">테이블 가져오기</v-btn>
                <v-btn outlined block color="primary" class="mt-2" @click="oepnCreateTableDialog">테이블 추가하기</v-btn>
                <div v-if="selectedTable" class="mt-2">
                    <hh-input dense v-model="selectedTable.name" class="input"></hh-input>
                    <div class="input-header">위치(x,y)</div>
                    <v-layout align-center class="input pl-2">
                        <div>x</div><v-text-field v-model.number="selectedTable.AreaTable.x" solo flat
                            hide-details></v-text-field>
                        <div>y</div><v-text-field v-model.number="selectedTable.AreaTable.y" solo flat
                            hide-details></v-text-field>
                    </v-layout>
                    <div class="input-header">너비</div>
                    <v-text-field class="input" v-model.number="selectedTable.AreaTable.width" solo flat
                        hide-details></v-text-field>
                    <div class="input-header">높이</div>
                    <v-text-field class="input" v-model.number="selectedTable.AreaTable.height" solo flat
                        hide-details></v-text-field>

                    <v-btn class="mt-2" outlined block color="error" @click="removeTable">테이블 삭제</v-btn>
                </div>


            </div>
            <!-- <div class="table-list">
                <div class="table-item" @click="clickTable(table)" v-for="(table,key) in tables" :key="key">
                    {{table.name}}
                </div>
            </div> -->
        </v-layout>

        <v-dialog v-model="tableDialog.visible" width="1000px" content-class="table-dialog">
            <div class="contents">
                <v-layout>
                    <div class="table-list">
                        <div class="table-item" :class="{ selected: isSelectTable(table) }" @click="toggleTable(table)"
                            v-for="(table, key) in tables" :key="key">
                            {{ table.name }}
                        </div>
                    </div>
                    <div class="table-config-container">
                        <div class="input-header">너비</div>
                        <hh-money v-model="tableDialog.config.width" class="input" dense></hh-money>
                        <div class="input-header">높이</div>
                        <hh-money v-model="tableDialog.config.height" class="input" dense></hh-money>

                        <div class="text-center">
                            <v-btn color="error" text @click="closeTableDialog">취소</v-btn>
                            <v-btn color="primary" text @click="addTable">추가</v-btn>
                        </div>

                    </div>
                </v-layout>

                <div class="text-center">


                </div>
            </div>


        </v-dialog>


        <v-dialog v-model="areaDialog.visible" width="500px">
            <v-card v-if="areaDialog.item" class="pa-2">
                <div class="input-header">
                    영역 이름
                </div>
                <hh-input class="input" placeholder="1층" v-model="areaDialog.item.name" dense></hh-input>

                <div class="text-center mt-2">
                    <v-btn color="error" text @click="areaDialog.visible = false">취소</v-btn>
                    <v-btn color="primary" text @click="areaSave">저장</v-btn>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog v-model="createTableDialog.visible" width="500px">
            <v-card class="pa-2" v-if="createTableDialog.item">
                <div class="input-header">
                    테이블이름
                </div>
                <v-text-field class="input" v-model="createTableDialog.item.name" dense solo flat
                    hide-details></v-text-field>
                <div class="text-center mt-2">
                    <v-btn color="error" text @click="createTableDialog.visible = false">취소</v-btn>
                    <v-btn color="primary" text @click="createTable">저장</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { select } from 'underscore';
import VueDraggableResizable from 'vue-draggable-resizable'
import draggable from "vuedraggable";
export default {
    components: {
        draggable,
        VueDraggableResizable
    },
    data() {
        return {
            createTableDialog: {
                visible: false,
                item: null
            },
            tableDialog: {
                visible: false,
                config: {
                    width: 100,
                    height: 90
                },
                selectedTables: []
            },
            areaDialog: {
                visible: false,
                item: null
            },
            selectedArea: null,
            selectedTable: null,
            areas: [],
            tables: []
        }
    },
    mounted() {
        this.getAreaList()
        this.getTableList()
    },
    methods: {
        createTable() {
            if (!this.createTableDialog.item.name) {
                window.alert("테이블 이름을 입력해주세요")
                return
            }
            if (this.selectedArea) {
                this.selectedArea.tables.push({
                    name: this.createTableDialog.item.name,
                    AreaTable: this.createTableDialog.item.AreaTable
                })
            }
            this.createTableDialog.visible = false
        },
        oepnCreateTableDialog() {
            this.createTableDialog.visible = true
            this.createTableDialog.item = {
                AreaTable: {
                    width: 100,
                    height: 90,
                    x: 0,
                    y: 0,
                    containerWidth: this.$refs.container.clientWidth,
                    containerHeight: this.$refs.container.clientHeight,
                    AreaId: this.selectedArea.id,
                    deleted: false
                },
                name: "",
                width: 100,
                height: 90

            }

        },
        closeTableDialog() {
            this.tableDialog.visible = false
            this.tableDialog.selectedTables = []
            this.tableDialog.config = {
                width: 100,
                height: 90
            }
        },
        removeTable() {
            if (!this.selectedTable) {
                return
            }
            if (!window.confirm(`'${this.selectedTable.name}'테이블을 삭제하시겠습니까?`)) {
                return
            }
            this.$axios.post("/root/areatable/remove", this.selectedTable)
                .then(result => {
                    this.getAreaList()
                })
        },
        isSelectTable(table) {
            return this.tableDialog.selectedTables.includes(table)
        },
        removeArea(area) {
            if (!window.confirm(`'${area.name}'영역을 삭제하시겠습니까?`)) {
                return
            }
            this.$axios.post("/root/area/remove", {
                id: area.id
            }).then(res => {
                this.getAreaList()
            })
        },
        onActivated(table) {

        },
        isActiveTableItem(table) {
            if (!this.selectedTable) {
                return false
            }
            if (table.tableId && this.selectedTable.tableId == table.tableId) {
                return true
            }
            if (!table.tableId && this.selectedTable.id == table.id) {
                return true
            }

            return false
        },
        onActivated(table) {
            this.selectedTable = table
        },
        onResize(x, y, width, height) {

            this.selectedTable.AreaTable.x = x
            this.selectedTable.AreaTable.y = y
            this.selectedTable.AreaTable.width = width
            this.selectedTable.AreaTable.height = height

        },
        onDrag(x, y) {

            this.selectedTable.AreaTable.x = x
            this.selectedTable.AreaTable.y = y
        },
        openTableDialog() {
            this.tableDialog.visible = true
        },
        addTable() {
            if (this.tableDialog.selectedTables.length == 0) {
                window.alert("테이블을 선택해주세요")
                return
            }
            this.tableDialog.selectedTables.forEach(table => {
                if (this.selectedArea && this.selectedArea.tables) {
                    table.AreaTable = {
                        width: this.tableDialog.config.width,
                        height: this.tableDialog.config.height,
                        x: 0,
                        y: 0
                    }

                    this.selectedArea.tables.push(table)
                }
            })
            this.closeTableDialog()
        },
        toggleTable(table) {
            if (this.tableDialog.selectedTables.includes(table)) {
                this.tableDialog.selectedTables = this.tableDialog.selectedTables.filter(t => t.id != table.id)
            } else {
                this.tableDialog.selectedTables.push(table)
            }
            console.log(this.tableDialog.selectedTables)
        },
        // addTable(table) {
        //     if (this.selectedArea && this.selectedArea.tables) {
        //         table.AreaTable = {
        //             width: 100,
        //             height: 90,
        //             x: 0,
        //             y: 0
        //         }

        //         this.selectedArea.tables.push(table)
        //     }
        //     this.tableDialog.visible = false

        // },
        areaSave() {
            if (!this.areaDialog.item.name) {
                window.alert("영역 이름을 입력해주세요")
                return
            }
            this.$axios.post("/root/area/save", this.areaDialog.item).then(res => {
                this.areaDialog.visible = false
                this.getAreaList()
            })
        },
        openAreaDialog() {
            this.areaDialog.visible = true
            this.areaDialog.item = {
                storeId: this.$route.params.id,
                name: "",

            }
        },
        selectArea(area) {
            this.selectedArea = area
        },
        getAreaList() {
            this.$axios.post("/root/area/list", {
                storeId: this.$route.params.id
            }).then(res => {

                if (res.data.res) {
                    const containerWidth = this.$refs.container.clientWidth
                    const containerHeight = this.$refs.container.clientHeight
                    _.each(res.data.list, area => {
                        _.each(area.tables, table => {
                            var xScale = containerWidth / table.AreaTable.containerWidth
                            var yScale = containerHeight / table.AreaTable.containerHeight

                            table.AreaTable.x = Math.floor(table.AreaTable.x * xScale)
                            table.AreaTable.y = Math.floor(table.AreaTable.y * yScale)
                            table.AreaTable.width = Math.floor(table.AreaTable.width * xScale)
                            table.AreaTable.height = Math.floor(table.AreaTable.height * yScale)
                            table.AreaTable.containerWidth = containerWidth
                            table.AreaTable.containerHeight = containerHeight

                        })
                    })
                    this.areas = res.data.list
                    if (this.areas.length > 0) {
                        this.selectArea(_.first(this.areas))
                    }
                }

            })
        },
        getTableList() {
            this.$axios.post("/root/table/list", {
                storeId: this.$route.params.id
            }).then(res => {
                this.tables = res.data.list
            })
        },
        save() {
            this.$axios.post("/root/table/save", {
                storeId: this.$route.params.id,
                areas: this.areas,
                container: {
                    width: this.$refs.container.clientWidth,
                    height: this.$refs.container.clientHeight
                }
            }).then(res => {
                window.alert("저장되었습니다")
            })
        }
    }
}
</script>

<style scoped lang="less">
.table-manage-root {
    width: 100%;
    height: 100%;

    padding: 10px;
}

.draggable-container {
    display: inline-block;
}

.area-list-container {
    // display: flex;
    // flex-direction: column;
    // width: 200px;
    // height: 100%;


    .area-item {
        display: inline-block;
        padding: 10px;
        border-radius: 5px 5px 0 0;
        cursor: pointer;
        min-width: 150px;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        margin-left: 5px;
        margin-right: 5px;


        border-top: 1px solid #eee;
        border-left: 1px solid #eee;
        border-right: 1px solid #eee;
        background-color: #fff;

        &.active {
            background-color: #f5f5f5;
            border-top: 0;
            border-left: 0;
            border-right: 0;

        }

    }
}

.area-container {
    aspect-ratio: 16/9;
    height: 680px;
    background-color: #f5f5f5;

    .table-item {
        position: absolute;
        padding: 10px;
        background: white;
    }
}

.function-container {
    margin-left: 10px;
    width: 250px;
}


.table-dialog {
    .contents {
        padding: 20px;
        background: white;
    }

    .table-list {

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;


        .table-item {
            cursor: pointer;
            padding: 5px 10px;
            margin-bottom: 5px;
            text-align: center;
            border-radius: 5px;
            border: 1px solid #eee;
            min-width: 200px;

            &.selected {
                background: #f5f5f5;
            }

            &:hover {
                background: #f5f5f5;
            }
        }
    }
}
</style>